import type { IconBaseProps } from '@/components/icons/type';

export const IconFanClub = ({ boxSize = '1.5em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="#1C1C1C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 17H9.55634V13.9547L11.3592 12.1097L14.1549 17H16L12.3662 10.9438L15.6549 7H13.8099L9.58451 12.1509H9.55634V7H8V17Z"
        fill="black"
      />
    </svg>
  );
};
