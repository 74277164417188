import type { IconBaseProps } from '@/components/icons/type';

export const IconCart = ({ boxSize = '1.5em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M9.25 20.75C10.0784 20.75 10.75 20.0784 10.75 19.25C10.75 18.4216 10.0784 17.75 9.25 17.75C8.42157 17.75 7.75 18.4216 7.75 19.25C7.75 20.0784 8.42157 20.75 9.25 20.75Z"
          stroke="#1C1C1C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.25 20.75C18.0784 20.75 18.75 20.0784 18.75 19.25C18.75 18.4216 18.0784 17.75 17.25 17.75C16.4216 17.75 15.75 18.4216 15.75 19.25C15.75 20.0784 16.4216 20.75 17.25 20.75Z"
          stroke="#1C1C1C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.75 2.75H5.59L8.15 14.26C8.33 15.14 9.13 15.76 10.04 15.75H17.33C18.24 15.77 19.04 15.14 19.22 14.26L20.74 6.46H6.42"
          stroke="#1C1C1C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
