import { clsx } from 'clsx';
import NextLink from 'next/link';
import { forwardRef } from 'react';

import type { ButtonLinkProps } from './type';

import { BUTTON_CLASS_NAMES } from '@/components/ui/button/const';
import { useVersionStore } from '@/stores';
import { stringifyUrl } from '@/utils/url';

import { BUTTON_LINK_THEME_CLASS_NAMES } from './const';

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      href,
      target,
      rel,
      theme = 'fill',
      size = 'md',
      rounded = 'md',
      roundedDirection = {
        topLeft: true,
        topRight: true,
        bottomLeft: true,
        bottomRight: true,
      },
      leftIcon,
      disabled = false,
      isBlock = true,
      children,
      prefetch,
    },
    ref
  ) => {
    const { requiresRefresh } = useVersionStore();

    const buttonChild = (
      <span
        className={clsx(
          'tw-cursor-pointer',
          BUTTON_CLASS_NAMES({
            theme,
            size,
            rounded,
            roundedDirection,
          }),
          BUTTON_LINK_THEME_CLASS_NAMES({ theme, disabled }),
          isBlock && 'tw-w-full',
          disabled && 'tw-pointer-events-none'
        )}
      >
        <span className={clsx('tw-mr-1')}>{leftIcon}</span>
        {children}
      </span>
    );

    return requiresRefresh ? (
      <a
        ref={ref}
        href={typeof href === 'string' ? href : stringifyUrl(href) ?? ''}
        target={target}
        rel={rel}
      >
        {buttonChild}
      </a>
    ) : (
      <NextLink
        ref={ref}
        href={href}
        target={target}
        rel={rel}
        prefetch={prefetch}
      >
        {buttonChild}
      </NextLink>
    );
  }
);

ButtonLink.displayName = 'ButtonLink';
