import { clsx } from 'clsx';

import type { InputCheckboxGroupProps } from './type';

export const DIRECTION = {
  row: 'row',
  col: 'col',
} as const;

const LIST_DIRECTION_CLASS_NAMES = ({
  direction,
}: Pick<InputCheckboxGroupProps, 'direction'>) => {
  if (direction === 'row') {
    return clsx('tw-flex-row');
  } else if (direction === 'col') {
    return clsx('tw-flex-col');
  }
};

export const LIST_CLASS_NAMES = ({
  direction,
}: Pick<InputCheckboxGroupProps, 'direction'>) => {
  return clsx(
    'tw-flex tw-flex-wrap',
    'tw-gap-x-8 tw-gap-y-4',
    LIST_DIRECTION_CLASS_NAMES({ direction })
  );
};
