import { clsx } from 'clsx';
import { useMemo } from 'react';

import type { NotificationBadgeProps } from './type';

import { DISPLAY_RANGE } from './const';

export const NotificationBadge = ({ count }: NotificationBadgeProps) => {
  const displayCount = useMemo(() => {
    if (count >= DISPLAY_RANGE.MAX) {
      return `${DISPLAY_RANGE.MAX}+`;
    } else {
      return count;
    }
  }, [count]);

  if (count < DISPLAY_RANGE.MIN) return null;

  return (
    <div
      className={clsx(
        'tw-min-w-4 tw-w-fit tw-h-4 tw-px-[5px] tw-flex tw-items-center tw-justify-center',
        'tw-bg-primary-500 tw-rounded-[8px]',
        'tw-text-ui10 tw-font-bold tw-text-white'
      )}
    >
      {displayCount}
    </div>
  );
};
