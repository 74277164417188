import { Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { Fragment } from 'react';
import { useLockedBody } from 'usehooks-ts';

import type { HalfModalProps } from './type';
import type { MouseEventHandler } from 'react';

import { IconClose } from '@/components/icons';

export const HalfModal = ({
  isOpen,
  title,
  ariaLabel,
  closeOnOverlayClick = true,
  withCloseButton = true,
  maxWidthPx,
  onClose,
  children,
  footer,
}: HalfModalProps) => {
  useLockedBody(isOpen, 'root');

  const handleClickOverlay: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!closeOnOverlayClick) return;
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleClickCloseButton = () => {
    onClose();
  };

  return (
    <>
      <Transition
        as={Fragment}
        show={isOpen}
        enter={clsx('tw-transition tw-duration-200')}
        enterFrom={clsx('tw-opacity-0')}
        enterTo={clsx('tw-opacity-100')}
        leave={clsx('tw-transition tw-duration-200')}
        leaveFrom={clsx('tw-opacity-100')}
        leaveTo={clsx('tw-opacity-0')}
      >
        <div
          className={clsx('tw-fixed tw-inset-0 tw-bg-black/60 tw-z-overlay')}
        />
      </Transition>
      <Transition
        as={Fragment}
        show={isOpen}
        enter={clsx(
          'tw-transform tw-transition tw-ease-in-out tw-duration-200 md:tw-transform-none'
        )}
        enterFrom={clsx('tw-translate-y-full md:tw-opacity-0')}
        enterTo={clsx('tw-translate-y-0 md:tw-opacity-100')}
        leave={clsx(
          'tw-transform tw-transition tw-ease-in-out tw-duration-200 md:tw-transform-none'
        )}
        leaveFrom={clsx('tw-translate-y-0 md:tw-opacity-100')}
        leaveTo={clsx('tw-translate-y-full md:tw-opacity-0')}
      >
        <div
          className={clsx('tw-fixed tw-inset-0 tw-overflow-y-auto tw-z-modal')}
        >
          <div
            className={clsx(
              'tw-absolute tw-w-full tw-flex tw-items-end tw-justify-center tw-min-h-[100dvh]',
              'md:tw-items-center'
            )}
            onClick={handleClickOverlay}
          >
            <div
              role="dialog"
              aria-modal
              aria-label={ariaLabel}
              className={clsx(
                'tw-relative tw-min-w-full tw-flex tw-flex-col tw-pt-4 tw-px-spContentSide tw-bg-white tw-rounded-t-2xl',
                'md:tw-min-w-[440px] md:tw-p-10 md:tw-rounded-2xl tw-max-h-[92dvh]',
                footer ? 'tw-pb-0 md:tw-pb-0' : 'tw-pb-6'
              )}
              style={{ maxWidth: `${maxWidthPx}px` }}
            >
              {withCloseButton && (
                <>
                  {/* for PC */}
                  <button
                    aria-label="閉じる"
                    className={clsx(
                      'tw-absolute tw--top-10 tw-right-0 tw-text-white',
                      'hover:tw-opacity-70 tw-transition tw-duration-200',
                      'tw-hidden md:tw-block'
                    )}
                    onClick={handleClickCloseButton}
                  >
                    <IconClose />
                  </button>
                  {/* for SP */}
                  <button
                    aria-label="閉じる"
                    className={clsx(
                      'tw-absolute tw-top-4 tw-right-4',
                      'md:tw-hidden'
                    )}
                    onClick={handleClickCloseButton}
                  >
                    <IconClose />
                  </button>
                </>
              )}
              <div
                className={clsx(
                  'tw-text-head8 md:tw-text-head20 tw-font-bold tw-mb-4 md:tw-mb-6 empty:tw-mb-0 tw-text-center'
                )}
              >
                {title}
              </div>
              <div
                className={clsx(
                  'tw-flex-1 tw-overflow-y-auto tw-hidden-scrollbar',
                  !!footer && 'tw-pb-4'
                )}
              >
                {children}
              </div>
              {footer && (
                <footer
                  className={clsx(
                    'tw-sticky tw-bottom-0 -tw-mx-spContentSide md:tw--mx-10 tw-border-t tw-px-4 tw-py-4 tw-border-gray-200 tw-z-sticky'
                  )}
                >
                  {footer}
                </footer>
              )}
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};
