import type { Size, Border } from './type';

export const sizeClassName: Record<Size, string> = {
  sm: 'tw-h-12',
  md: 'tw-h-14',
};

export const borderClassName: Record<Border, string> = {
  top: 'tw-border-t',
  bottom: 'tw-border-b',
  none: 'tw-border-none',
};
