import reactStringReplace from 'react-string-replace';

import type { InputOption } from '@/types/ui';

export const generateShareURL = (
  baseUrl: string,
  params: Record<string, string>
) => {
  const queryString = new URLSearchParams(params).toString();

  return `${baseUrl}?${queryString}`;
};

export const generateOptions = <T extends InputOption['value']>(
  arr: Array<{ id: T; name: string }>
) => arr.map(({ id, name }) => ({ label: name, value: id }));

export const generateRangeOptions = (
  arr: Array<{ id: number; gt?: number; lt?: number }>,
  unit: string
) => {
  return arr.map(({ id, gt, lt }) => {
    if (gt !== undefined && lt !== undefined) {
      return { label: `${gt}${unit}～${lt}${unit}未満`, value: id };
    } else if (gt !== undefined && lt === undefined) {
      return { label: `${gt}${unit}以上`, value: id };
    } else if (gt === undefined && lt !== undefined) {
      return { label: `${lt}${unit}未満`, value: id };
    } else {
      return { label: '', value: id };
    }
  });
};

export const formatCountNumber = (
  value: number,
  min: number,
  max: number
): string => {
  if (value > max) return `${String(max)}+`;
  if (value < min) return String(min);
  return String(value);
};

/**
 * @default top: 0, behavior: 'smooth'
 */
export const scrollTo = (options?: ScrollToOptions) =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
    ...options,
  });

const regExp = /(https?:\/\/\S+)/g;

export const urlToBlankLink = (url: string) => {
  return reactStringReplace(url, regExp, (match) => (
    <a
      href={match}
      className="tw-text-link hover:tw-opacity-70 tw-transition tw-duration-200 tw-break-all"
      target="_blank"
      rel="noreferrer nofollow"
    >
      {match}
    </a>
  ));
};
