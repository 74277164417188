import { clsx } from 'clsx';
import NextImage from 'next/image';

import type { ImageProps } from './type';
import type { ImageLoader } from 'next/image';
import type { ReactEventHandler } from 'react';

import { NO_IMAGE_SRC } from '@/constants';

import { OBJECT_FIT_CLASS_NAMES } from './const';
import { generateSizes } from './util';

const imageDeliveryDomain = process.env.NEXT_PUBLIC_IMAGE_DELIVERY_DOMAIN;
const imageOriginalDomain = process.env.NEXT_PUBLIC_IMAGE_ORIGINAL_DOMAIN;

const loader: ImageLoader | undefined = imageDeliveryDomain
  ? ({ src, width, quality }) => {
      if (!src.startsWith(`https://${imageOriginalDomain}`)) {
        return src;
      }
      const pathname = src.replace(`https://${imageOriginalDomain}`, '');
      return quality
        ? `https://${imageDeliveryDomain}${pathname}?format=webp&quality=${quality}&width=${width}`
        : `https://${imageDeliveryDomain}${pathname}?format=webp&width=${width}`;
    }
  : undefined;

export const Image = ({
  src,
  alt = '',
  objectFit = 'scale-down',
  priority = false,
  size,
  unoptimized = false,
  className,
}: ImageProps) => {
  const onImageError: ReactEventHandler<HTMLImageElement> = (event) => {
    const imageElement = event.target as HTMLImageElement;
    imageElement.onerror = null;
    imageElement.src = NO_IMAGE_SRC;
  };

  return (
    <NextImage
      src={src}
      alt={alt}
      fill
      priority={priority}
      className={clsx(
        '!tw-static',
        OBJECT_FIT_CLASS_NAMES(objectFit),
        className
      )}
      sizes={generateSizes(size)}
      unoptimized={loader ? unoptimized : true}
      loader={loader}
      onError={onImageError}
    />
  );
};
