import { useShoppingCart } from 'use-shopping-cart';

import type { Maybe, Price, Product } from '@/graphql/generated';

import { useToast } from '@/hooks/utils';

export type CartProduct = Omit<
  Product,
  'images' | 'prices' | 'defaultPrice' | 'isActive'
> & {
  defaultPrice?: Maybe<Omit<Price, 'product' | 'recurring'>>;
};

/**
 * use-shopping-cartのラッパー
 *
 * 型やIFの制限を行う
 */
export function useCart() {
  const {
    addItem: cartAddItem,
    removeItem: cartRemoveItem,
    setItemQuantity,
    clearCart,
    cartDetails,
    cartCount,
    totalPrice,
  } = useShoppingCart();
  const { showToast } = useToast();

  const addItem = (product: CartProduct) => {
    if (product.defaultPrice == null || product.defaultPrice?.amount == null)
      return;

    cartAddItem({
      price_id: product.defaultPrice.id,
      name: product.name,
      price: product.defaultPrice.amount,
      sku: `${product.id}_${product.defaultPrice.id}`,
      currency: 'JPY',
    });
    showToast('success', 'カートに追加しました');
  };

  const removeItem = (priceId: Price['id']) => {
    cartRemoveItem(priceId);
    showToast('success', 'カートから商品を削除しました');
  };

  /**
   * 指定商品の個数を指定個数に変更する
   *
   * @param priceId
   * @param amount
   */
  const changeAmount = (priceId: Price['id'], amount: number | string) => {
    setItemQuantity(
      priceId,
      typeof amount === 'string' ? Number(amount) : amount
    );
  };

  return {
    addItem,
    removeItem,
    changeAmount,
    clearCart,
    cartDetails,
    cartCount,
    totalPrice,
  };
}
