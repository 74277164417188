import type { ContestDetailedSearchFormVO } from './type';

import { z } from '@/libs/zod';

export const contestDetailedSearchFormSchema = z.object({
  keyword: z.string().optional(),
  categoryIds: z.array(z.number()),
  subcategoryIds: z.array(z.number()),
  jobIds: z.array(z.number()),
  prefectureIds: z.array(z.number()),
  prizeTopAmountId: z.number().optional().nullable(),
  acceptanceTotalId: z.number().optional().nullable(),
  entryLastTotalId: z.number().optional().nullable(),
  endDate: z.object({
    from: z.string().optional(),
    to: z.string().optional(),
  }),
  includesClosed: z.boolean(),
  hasParticipationAward: z.boolean(),
  isRecommended: z.boolean(),
  notRequiresFee: z.boolean(),
  notRequiresSignup: z.boolean(),
});

export const formInitialValues: ContestDetailedSearchFormVO = {
  keyword: '',
  categoryIds: [],
  subcategoryIds: [],
  jobIds: [],
  prefectureIds: [],
  prizeTopAmountId: undefined,
  acceptanceTotalId: undefined,
  entryLastTotalId: undefined,
  endDate: {
    from: undefined,
    to: undefined,
  },
  includesClosed: false,
  hasParticipationAward: false,
  isRecommended: false,
  notRequiresFee: false,
  notRequiresSignup: false,
};

export const formLabel: Record<keyof ContestDetailedSearchFormVO, string> = {
  keyword: 'キーワード',
  categoryIds: 'カテゴリ',
  subcategoryIds: 'サブカテゴリ',
  jobIds: '応募資格',
  prefectureIds: '地域限定',
  prizeTopAmountId: '1位賞金額',
  acceptanceTotalId: '入選者多数',
  entryLastTotalId: '前回応募数',
  endDate: '締切',
  includesClosed: '締切を過ぎた公募を含める',
  hasParticipationAward: '参加賞あり',
  isRecommended: '編集部おすすめ',
  notRequiresFee: '出品料無料',
  notRequiresSignup: '他サービスへの登録不要',
};

export const placeholder: Record<keyof ContestDetailedSearchFormVO, string> = {
  keyword: 'キーワードを入力',
  categoryIds: '',
  subcategoryIds: '',
  jobIds: '',
  prefectureIds: '',
  prizeTopAmountId: '選択しない',
  acceptanceTotalId: '選択しない',
  entryLastTotalId: '選択しない',
  endDate: '年/月/日',
  includesClosed: '',
  hasParticipationAward: '',
  isRecommended: '',
  notRequiresFee: '',
  notRequiresSignup: '',
};
