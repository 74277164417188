import type { IconBaseProps } from '@/components/icons/type';

export const IconOvalArrowRight = ({ boxSize = '1.5em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.3536 8.14645C11.1583 7.95118 10.8417 7.95118 10.6464 8.14645C10.4512 8.34171 10.4512 8.65829 10.6464 8.85355L13.2929 11.5L10.6464 14.1464C10.4512 14.3417 10.4512 14.6583 10.6464 14.8536C10.8417 15.0488 11.1583 15.0488 11.3536 14.8536L14.3536 11.8536C14.5488 11.6583 14.5488 11.3417 14.3536 11.1464L11.3536 8.14645Z"
        fill="currentColor"
      />
    </svg>
  );
};
