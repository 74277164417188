import type { IconBaseProps } from '@/components/icons/type';

export const IconCalendar = ({ boxSize = '1.5em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3847_207906)">
        <g clipPath="url(#clip1_3847_207906)">
          <path
            d="M19.2 3.81818H18.3V2H16.5V3.81818H7.5V2H5.7V3.81818H4.8C3.81 3.81818 3 4.63636 3 5.63636V20.1818C3 21.1818 3.81 22 4.8 22H19.2C20.19 22 21 21.1818 21 20.1818V5.63636C21 4.63636 20.19 3.81818 19.2 3.81818ZM19.2 20.1818H4.8V8.36364H19.2V20.1818Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
