import { Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import {
  Fragment,
  type MouseEventHandler,
  createElement,
  useMemo,
} from 'react';
import { useLockedBody } from 'usehooks-ts';

import type { GlobalMenuModalProps } from './type';

import { MyPageMenuList } from '@/components/features/mypage';
import {
  IconClose,
  IconChevronRight,
  IconOpenInNew,
  IconDefaultProfile,
} from '@/components/icons';
import {
  Accordion,
  AnchorLink,
  Button,
  Image,
  NotificationBadge,
} from '@/components/ui';
import { ModalOverlay } from '@/components/ui/modal/ModalOverlay';
import { ROUTE } from '@/constants';
import { GlobalMenuModalLogoutDocument } from '@/graphql/generated.creator';
import { useCart } from '@/hooks/features/cart';
import { useToast } from '@/hooks/utils';
import { useMutationWrapper } from '@/libs/apollo';
import BrandLogo from 'public/images/brand_logo.svg';

import { MENUS, SUB_MENUS } from './const';

export const GlobalMenuModal = ({
  creator,
  isOpen,
  onClose,
  onClickLogin,
  onClickSignup,
}: GlobalMenuModalProps) => {
  useLockedBody(isOpen, 'root');

  const router = useRouter();

  const { showToast } = useToast();

  const { cartCount } = useCart();

  const [logoutMutation] = useMutationWrapper(GlobalMenuModalLogoutDocument, {
    refetchQueries: 'active',
    role: 'creator',
  });

  // NOTE: 通知の状態をメニューに反映させる
  const menus = useMemo(() => {
    return MENUS.map((menu) => {
      if (menu.href === ROUTE.cart) {
        return {
          ...menu,
          notification: cartCount,
        };
      }
      return menu;
    });
  }, [cartCount]);

  const handleClickOverlay: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleClickCloseButton = () => {
    onClose();
  };

  const handleLogout = () => {
    logoutMutation({
      onCompleted: () => {
        showToast('success', 'ログアウトしました');
        router.push(ROUTE.top());
      },
    });
  };

  return (
    <>
      {isOpen && <ModalOverlay />}
      <Transition
        as={Fragment}
        show={isOpen}
        enter={clsx(
          'tw-transform tw-transition tw-ease-in-out tw-duration-200'
        )}
        enterFrom={clsx('tw-translate-x-full tw-translate-y-0')}
        enterTo={clsx('tw-translate-x-0')}
        leave={clsx(
          'tw-transform tw-transition tw-ease-in-out tw-duration-200'
        )}
        leaveFrom={clsx('tw-translate-x-0')}
        leaveTo={clsx('tw-translate-x-full tw-translate-y-0')}
      >
        <div
          className={clsx('tw-fixed tw-inset-0 tw-overflow-y-auto tw-z-modal')}
        >
          <div
            className={clsx(
              'tw-absolute tw-w-full tw-flex tw-items-end tw-justify-center tw-min-h-screen',
              'md:tw-justify-end'
            )}
            onClick={handleClickOverlay}
          >
            <div
              role="dialog"
              aria-modal
              aria-label="メニュー"
              className={clsx(
                'tw-relative tw-h-screen tw-min-w-full tw-flex tw-flex-col',
                'md:tw-min-w-[400px]'
              )}
            >
              {/* close button for PC/tablet */}
              <button
                type="button"
                aria-label="閉じる"
                className={clsx(
                  'tw-absolute tw-top-4 tw--left-12 tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center',
                  'tw-bg-black/70 tw-rounded-circle tw-text-white',
                  'hover:tw-opacity-70 tw-transition tw-duration-200',
                  'tw-invisible md:tw-visible'
                )}
                onClick={handleClickCloseButton}
              >
                <IconClose />
              </button>

              {/* close button for SP */}
              <header
                className={clsx(
                  'tw-relative tw-h-12 tw-px-4 tw-flex tw-items-center tw-justify-between tw-bg-white',
                  'tw-visible md:tw-hidden'
                )}
              >
                <div />
                <BrandLogo width={78} />
                <button
                  type="button"
                  aria-label="閉じる"
                  className={clsx(
                    'hover:tw-opacity-70 tw-transition tw-duration-200'
                  )}
                  onClick={handleClickCloseButton}
                >
                  <IconClose />
                </button>
              </header>

              <div
                className={clsx(
                  'tw-pt-4 tw-px-spContentSide tw-bg-white',
                  'md:tw-pt-8 md:tw-px-8'
                )}
              >
                {creator ? (
                  <Accordion
                    label={
                      <div
                        className={clsx('tw-flex tw-items-center tw-gap-x-2')}
                      >
                        <div
                          className={clsx(
                            'tw-h-6 tw-w-6 tw-rounded-circle tw-overflow-hidden'
                          )}
                        >
                          {creator.profileImage?.url ? (
                            <Image
                              src={creator.profileImage?.url}
                              alt={creator.nickname}
                              objectFit="cover"
                            />
                          ) : (
                            <IconDefaultProfile boxSize="24" />
                          )}
                        </div>
                        <span>マイページ</span>
                      </div>
                    }
                    initialOpenState={false}
                    border="bottom"
                    size="sm"
                  >
                    <div className={clsx('tw-pl-8')}>
                      <div className={clsx('tw-border-b tw-border-gray-300')}>
                        <MyPageMenuList size="sm" />
                      </div>
                    </div>
                  </Accordion>
                ) : (
                  <div
                    className={clsx(
                      'tw-mb-4 tw-flex tw-items-center tw-gap-x-2'
                    )}
                  >
                    <Button theme="fill" size="sm" onClick={onClickSignup}>
                      新規登録
                    </Button>
                    <Button theme="outline" size="sm" onClick={onClickLogin}>
                      ログイン
                    </Button>
                  </div>
                )}
              </div>

              <nav
                className={clsx(
                  'tw-flex-1 tw-px-spContentSide tw-pb-10 tw-bg-white tw-overflow-y-auto tw-hidden-scrollbar',
                  'md:tw-px-8'
                )}
              >
                <ul>
                  {menus.map(
                    ({ label, href, icon, notification, disabled }) => (
                      <li
                        key={label}
                        className={clsx('tw-border-b tw-border-gray-200')}
                      >
                        <AnchorLink
                          href={href}
                          disabled={disabled}
                          className={clsx(
                            'tw-h-12 tw-w-full tw-flex tw-items-center tw-justify-between'
                          )}
                          onClickCapture={onClose}
                        >
                          <>
                            <p
                              className={clsx(
                                'tw-flex tw-items-center tw-space-x-2'
                              )}
                            >
                              {createElement(icon, { boxSize: '24px' })}
                              <span
                                className={clsx('tw-text-ui16 tw-font-bold')}
                              >
                                {label}
                              </span>
                              {notification !== undefined && (
                                <span className="!tw-ml-1">
                                  <NotificationBadge count={notification} />
                                </span>
                              )}
                            </p>
                            <span className={clsx('tw-text-gray-400')}>
                              <IconChevronRight boxSize="16px" />
                            </span>
                          </>
                        </AnchorLink>
                      </li>
                    )
                  )}
                </ul>

                <ul className={clsx('tw-mt-6 tw-space-y-4')}>
                  {SUB_MENUS.map(({ label, href, isExternal }) => (
                    <li key={href}>
                      <AnchorLink
                        href={href}
                        target={isExternal ? '_blank' : undefined}
                        className={clsx('tw-flex tw-items-center tw-space-x-1')}
                      >
                        <>
                          <span>{label}</span>
                          {isExternal && <IconOpenInNew boxSize="16px" />}
                        </>
                      </AnchorLink>
                    </li>
                  ))}
                  {creator && (
                    <li key="logout">
                      <button
                        type="button"
                        className={clsx(
                          'hover:tw-opacity-50 tw-transition tw-duration-200'
                        )}
                        onClick={handleLogout}
                      >
                        ログアウト
                      </button>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};
