export const DATE_FORMAT = {
  yyyyMMdd: 'yyyy/MM/dd',
  yyyyMMddHHmm: 'yyyy/MM/dd HH:mm',
  yyyyMMddHHmmss: 'yyyy/MM/dd HH:mm:ss',
  yyyyMMdd_hyphen: 'yyyy-MM-dd',
  yyyyMd_jp: 'yyyy年M月d日',
  yyyyMdeee: 'yyyy年M月d日（eee）',
  yyyyMdeeeHHmm: 'yyyy年M月d日（eee）HH:mm',
  yyyyMd: 'yyyy/M/d',
  Md: 'M/d',
};
