import type { Theme } from './type';

export const themeCss: Record<Theme, string> = {
  fill: `
    input.gsc-input {
      background-color: transparent !important;
    }
    .gsc-input-box {
      background-color: #f3f5f7 !important;
      border: none !important;
    }
    .gsc-input-box:hover {
      background-color: #EBEEF1 !important;
    }
    .gsc-input-box:focus-within {
      outline: rgba(38, 110, 217, 0.5) solid 2px !important;
      background-color: #fff !important;
    }
  `,
  outline: `
  input.gsc-input {
    background-color: transparent !important;
  }
  .gsc-input-box:hover {
    border-color: #76828F !important;
  }
  .gsc-input-box:focus-within {
    outline: rgba(38, 110, 217, 0.5) solid 2px !important;
    border: none !important;
  }
  `,
};

export const baseCss = `
.gsc-control-cse,
.table.gsc-search-box td.gsc-input,
table.gsc-search-box td.gsc-input {
  padding: 0 !important;
}

.gsc-search-box {
  margin-bottom: 0;
}

.gsc-modal-background-image {
  background-color: rgba(28, 28, 28, 0.8) !important;
}

.gsc-input-box {
  border-radius: 20px;
}

.gsc-input-box .gsib_a {
  position: relative;
  height: 40px;
}

.gsc-input-box .gsib_a .gsc-input {
  padding-left: 2em !important;
}

.gsc-input-box .gsib_a::before {
  display: inline-block;
  content: url('/images/search.svg');
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -8px;
  margin-left: 17px;
}

.gsst_b,
.gsc-search-button {
  display: none;
}
`;
