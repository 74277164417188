export const BREAKPOINTS = {
  base: 0,
  sm: 375,
  md: 768,
  lg: 1124,
} as const;

export type BreakpointType = typeof BREAKPOINTS;

export const CONTENT_WIDTH = {
  sp: 375,
  tablet: 700,
  pc: 1124,
  pcMain: 760,
  singleColumn: 740,
} as const;
