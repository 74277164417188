import type { ContestDetailedSearchFormVO } from './type';
import type { ContestsBoolExp } from '@/graphql/generated';

import {
  NON_JOB_OPTION,
  NON_PREFECTURE_OPTION,
  PRIZE_TOP_AMOUNTS,
  ACCEPTANCE_TOTALS,
  ENTRY_LAST_TOTALS,
  CONTEST_ENTRY_STATUS,
} from '@/constants/features/contest';
import { masterData } from '@/data/master';
import { allLikeExpression } from '@/utils/graphql';

const generateKeywordWhere = ({
  keyword,
}: Pick<ContestDetailedSearchFormVO, 'keyword'>): ContestsBoolExp => {
  if (!keyword) return {};
  return {
    _or: [
      { name: { _like: allLikeExpression(keyword) } },
      { description: { lead: { _like: allLikeExpression(keyword) } } },
      { description: { prizeTop: { _like: allLikeExpression(keyword) } } },
      { taggings: { tag: { name: { _eq: keyword } } } },
    ],
  };
};

const generateCategoryWhere = ({
  categoryIds,
  subcategoryIds,
}: Pick<
  ContestDetailedSearchFormVO,
  'categoryIds' | 'subcategoryIds'
>): ContestsBoolExp => {
  const filterdCategories: {
    categoryId?: number;
    subcategoryIds?: number[];
  }[] = masterData.contestCategories.map((mc) => {
    const allSubcategoriesIncluded = mc.subcategories.every((msc) =>
      subcategoryIds.includes(msc.id)
    );
    if (allSubcategoriesIncluded) {
      return {
        categoryId: mc.id,
      };
    }
    if (categoryIds.includes(mc.id)) {
      return {
        categoryId: mc.id,
      };
    }
    return {
      subcategoryIds: subcategoryIds.filter((sc) =>
        mc.subcategories.some((msc) => msc.id === sc)
      ),
    };
  });
  const filterdCategoryIds = filterdCategories
    .map((c) => c.categoryId)
    .filter((cId) => cId !== undefined) as number[];
  const filterdSubcategoryIds = filterdCategories
    .flatMap((c) => c.subcategoryIds)
    .filter((scId) => scId !== undefined) as number[];
  const base: ContestsBoolExp =
    filterdCategoryIds.length === 0 && filterdSubcategoryIds.length === 0
      ? {}
      : {
          _or: [
            ...(filterdCategoryIds.length > 0
              ? [
                  {
                    subcategory: {
                      categoryId: {
                        _in: filterdCategoryIds,
                      },
                    },
                  },
                ]
              : []),
            ...(filterdSubcategoryIds.length > 0
              ? [
                  {
                    subcategory: {
                      id: {
                        _in: filterdSubcategoryIds,
                      },
                    },
                  },
                ]
              : []),
          ],
        };
  return base;
};

const generateJobWhere = ({
  jobIds,
}: Pick<ContestDetailedSearchFormVO, 'jobIds'>): ContestsBoolExp => {
  if (jobIds.length === 0) return {};
  const baseWhere: ContestsBoolExp = {
    qualifications: { jobId: { _in: jobIds } },
  };
  const isIncludedNonJob = jobIds.includes(NON_JOB_OPTION.id);
  return isIncludedNonJob
    ? { _or: [baseWhere, { _not: { qualifications: {} } }] }
    : baseWhere;
};

const generatePrefectureWhere = ({
  prefectureIds,
}: Pick<ContestDetailedSearchFormVO, 'prefectureIds'>): ContestsBoolExp => {
  if (prefectureIds.length === 0) return {};
  const baseWhere: ContestsBoolExp = {
    localizations: { prefectureId: { _in: prefectureIds } },
  };
  const isIncludedNonPrefecture = prefectureIds.includes(
    NON_PREFECTURE_OPTION.id
  );
  return isIncludedNonPrefecture
    ? { _or: [baseWhere, { _not: { localizations: {} } }] }
    : baseWhere;
};

const generatePrizeTopAmountWhere = ({
  prizeTopAmountId,
}: Pick<ContestDetailedSearchFormVO, 'prizeTopAmountId'>): ContestsBoolExp => {
  const selectedPrizeTopAmount = PRIZE_TOP_AMOUNTS.find(
    ({ id }) => id === prizeTopAmountId
  );
  if (selectedPrizeTopAmount === undefined) return {};
  return {
    prize: {
      _and: [
        { amountTop: { _gte: selectedPrizeTopAmount.gt } },
        { amountTop: { _lt: selectedPrizeTopAmount.lt } },
      ],
    },
  };
};

const generateAcceptanceTotalWhere = ({
  acceptanceTotalId,
}: Pick<ContestDetailedSearchFormVO, 'acceptanceTotalId'>): ContestsBoolExp => {
  const selectedAcceptanceTotal = ACCEPTANCE_TOTALS.find(
    ({ id }) => id === acceptanceTotalId
  );
  if (selectedAcceptanceTotal === undefined) return {};
  return {
    prize: {
      _and: [
        { acceptanceTotalGe: { _eq: selectedAcceptanceTotal.gt } },
        { acceptanceTotalLt: { _eq: selectedAcceptanceTotal.lt } },
      ],
    },
  };
};

const generateEntryLastTotalWhere = ({
  entryLastTotalId,
}: Pick<ContestDetailedSearchFormVO, 'entryLastTotalId'>): ContestsBoolExp => {
  const selectedEntryLastTotal = ENTRY_LAST_TOTALS.find(
    ({ id }) => id === entryLastTotalId
  );
  if (selectedEntryLastTotal === undefined) return {};
  return {
    entry: {
      _and: [
        { lastTotal: { _gte: selectedEntryLastTotal.gt } },
        { lastTotal: { _lt: selectedEntryLastTotal.lt } },
      ],
    },
  };
};

const generateEndDateWhere = ({
  endDate: { from, to },
}: Pick<ContestDetailedSearchFormVO, 'endDate'>): ContestsBoolExp => {
  if (from === undefined && to === undefined) return {};
  return {
    entry: {
      _and: [
        from
          ? {
              _or: [
                { endDate: { _gte: from } },
                { endDate: { _isNull: true } },
              ],
            }
          : {},
        to ? { endDate: { _lte: to } } : {},
      ],
    },
  };
};

export const generateContestWhere = ({
  keyword,
  categoryIds,
  subcategoryIds,
  jobIds,
  prefectureIds,
  prizeTopAmountId,
  acceptanceTotalId,
  entryLastTotalId,
  endDate,
  includesClosed,
  hasParticipationAward,
  notRequiresFee,
  notRequiresSignup,
  isRecommended,
}: ContestDetailedSearchFormVO): ContestsBoolExp => {
  return {
    _and: [
      { isHidden: { _eq: false } },
      includesClosed
        ? {}
        : {
            entry: {
              statusSnapshot: { value: { _neq: CONTEST_ENTRY_STATUS.CLOSED } },
            },
          },
      generateKeywordWhere({ keyword }),
      generateCategoryWhere({ categoryIds, subcategoryIds }),
      generateJobWhere({ jobIds }),
      generatePrefectureWhere({ prefectureIds }),
      generatePrizeTopAmountWhere({ prizeTopAmountId }),
      generateAcceptanceTotalWhere({ acceptanceTotalId }),
      generateEntryLastTotalWhere({ entryLastTotalId }),
      generateEndDateWhere({ endDate }),
      hasParticipationAward
        ? { prize: { participationAward: { _eq: hasParticipationAward } } }
        : {},
      notRequiresFee ? { description: { entryFee: { _isNull: true } } } : {},
      notRequiresSignup ? { entry: { requiresSignup: { _eq: false } } } : {},
      isRecommended ? { isRecommended: { _eq: isRecommended } } : {},
    ],
  };
};
