import type { IconBaseProps } from '@/components/icons/type';

export const IconTop = ({ boxSize = '1.5em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10.58L11.06 3.33C11.61 2.89 12.39 2.89 12.93 3.33L21.99 10.58"
        stroke="#1C1C1C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.01953 8.21973V18.7197C5.01953 19.5497 5.68953 20.2197 6.51953 20.2197H17.5195C18.3495 20.2197 19.0195 19.5497 19.0195 18.7197V8.21973"
        stroke="#1C1C1C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5195 11.2197H10.5195C10.2434 11.2197 10.0195 11.4436 10.0195 11.7197V14.7197C10.0195 14.9959 10.2434 15.2197 10.5195 15.2197H13.5195C13.7957 15.2197 14.0195 14.9959 14.0195 14.7197V11.7197C14.0195 11.4436 13.7957 11.2197 13.5195 11.2197Z"
        stroke="#1C1C1C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
