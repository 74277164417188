import { Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { Fragment, type MouseEventHandler } from 'react';
import { Section, H } from 'react-headings';
import { useLockedBody } from 'usehooks-ts';

import type { ContestSearchModalProps } from './type';

import {
  ContestKeywordSearchBar,
  ContestCategorySearch,
} from '@/components/features/contest';
import { GoogleSearchBar } from '@/components/features/google';
import { IconClose, IconSort } from '@/components/icons';
import { Button } from '@/components/ui';
import { ModalOverlay } from '@/components/ui/modal/ModalOverlay';
import BrandLogo from 'public/images/brand_logo.svg';

export const ContestSearchModal = ({
  isOpen,
  onClose,
  onClickDetailedSearch,
}: ContestSearchModalProps) => {
  useLockedBody(isOpen, 'root');

  const handleClickOverlay: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleClickCloseButton = () => {
    onClose();
  };

  return (
    <>
      {isOpen && <ModalOverlay />}
      <Transition
        as={Fragment}
        show={isOpen}
        enter={clsx('tw-transition-opacity tw-duration-200')}
        enterFrom={clsx('tw-opacity-0')}
        enterTo={clsx('tw-opacity-100')}
        leave={clsx('tw-transition-opacity tw-duration-200')}
        leaveFrom={clsx('tw-opacity-100')}
        leaveTo={clsx('tw-opacity-0')}
      >
        <div
          className={clsx('tw-fixed tw-inset-0 tw-overflow-y-auto tw-z-modal')}
        >
          <div
            className={clsx(
              'tw-absolute tw-w-full tw-flex tw-items-end tw-justify-center tw-min-h-screen'
            )}
            onClick={handleClickOverlay}
          >
            <div
              role="dialog"
              aria-modal
              aria-label="公募を探すメニュー"
              className={clsx(
                'tw-relative tw-min-h-screen tw-min-w-full tw-flex tw-flex-col tw-bg-white'
              )}
            >
              <Section component={null}>
                <header
                  className={clsx(
                    'tw-relative tw-h-12 tw-px-4 tw-flex tw-items-center tw-justify-between'
                  )}
                >
                  <button
                    type="button"
                    aria-label="閉じる"
                    className={clsx(
                      'hover:tw-opacity-70 tw-transition tw-duration-200'
                    )}
                    onClick={handleClickCloseButton}
                  >
                    <IconClose />
                  </button>
                  <BrandLogo width={78} />
                  <div />
                </header>

                <section className={clsx('tw-p-4')}>
                  <GoogleSearchBar theme="outline" />
                </section>

                <section className={clsx('tw-pt-4 tw-pb-6 tw-bg-gray-100')}>
                  <Section
                    component={
                      <H className={clsx('tw-px-6 tw-text-ui16 tw-font-bold')}>
                        公募を探す
                      </H>
                    }
                  >
                    <section className={clsx('tw-mt-4 tw-px-4')}>
                      <ContestKeywordSearchBar />
                    </section>

                    <section className={clsx('tw-mt-6 tw-px-2')}>
                      <ContestCategorySearch titleSize="small" />
                    </section>

                    <section className={clsx('tw-mt-6 tw-px-6')}>
                      <Button
                        theme="fill"
                        leftIcon={<IconSort />}
                        onClick={onClickDetailedSearch}
                      >
                        こだわり条件から探す
                      </Button>
                    </section>
                  </Section>
                </section>
              </Section>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};
