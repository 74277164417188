import type { IconBaseProps } from '@/components/icons/type';

export const IconDefaultProfile = ({ boxSize = '136' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 136 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="136" height="136" fill="#BEC7CF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M92 39C92 52.2548 81.2548 63 68 63C54.7452 63 44 52.2548 44 39C44 25.7452 54.7452 15 68 15C81.2548 15 92 25.7452 92 39ZM10 135.85C10 85.2402 33.64 71.9102 68 71.9102C102.36 71.9102 126 85.2402 126 135.85H10Z"
        fill="#76828F"
      />
    </svg>
  );
};
