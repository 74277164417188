import { clsx } from 'clsx';
import { filter } from 'graphql-anywhere';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';

import type { GlobalHeaderProps } from './type';
import type { ContestDetailedSearchModalProps } from '@/components/features/contest/ContestDetailedSearchModal/type';

import { GlobalMenuModal } from '@/components/app/GlobalMenuModal';
import { AuthLoginModal, AuthSignupModal } from '@/components/features/auth';
import {
  ContestSearchModal,
  ContestDetailedSearchModal,
} from '@/components/features/contest';
import { GoogleSearchBar } from '@/components/features/google';
import {
  IconArticle,
  IconMenu,
  IconMyPage,
  IconChevronLeft,
  IconTsukuLog,
  IconSearch,
  IconWork,
  IconDefaultProfile,
} from '@/components/icons';
import {
  AnchorLink,
  ButtonLink,
  Image,
  LinkButtonSlider,
  NotificationBadge,
  TextLink,
} from '@/components/ui';
import { ROUTE, ROUTE_NAME, SITE } from '@/constants';
import { masterData } from '@/data/master';
import { GlobalMenuModalCreatorsFragmentDoc } from '@/graphql/generated';
import { useCart } from '@/hooks/features/cart';
import { useDisclosure } from '@/hooks/utils';
import { Container } from '@/layouts';
import { generateContestListUrlQuery } from '@/utils/features/contest';
import BrandLogo from 'public/images/brand_logo.svg';

import { ContestSearchMenu } from './ContestSearchMenu';

export const GlobalHeader = ({
  creator,
  customHeaderLink,
  activeCategoryId,
}: GlobalHeaderProps) => {
  const router = useRouter();
  const { cartCount } = useCart();

  const [isFixed, setIsFixed] = useState(false);

  const lastScrollTop = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const st = window.scrollY || document.documentElement.scrollTop;
      setIsFixed(st < lastScrollTop.current);
      lastScrollTop.current = st <= 0 ? 0 : st;
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const loginModalState = useDisclosure();
  const signupModalState = useDisclosure();

  const menuModalState = useDisclosure();

  const contestSearchModalState = useDisclosure();
  const contestDetailedSearchModalState = useDisclosure();

  const categoryItems = useMemo(
    () =>
      masterData.contestCategories.map(({ id, name, nameEn }) => ({
        text: name,
        href: ROUTE.categoryTop(nameEn),
        isActive: id === activeCategoryId,
      })) ?? [],
    [activeCategoryId]
  );

  const handleClickContestSearch = () => {
    contestSearchModalState.open();
  };

  const handleClickContestDetailedSearch = () => {
    contestSearchModalState.close();
    contestDetailedSearchModalState.open();
  };

  const handleDetailedSearch: ContestDetailedSearchModalProps['onSearch'] = (
    condition
  ) => {
    const newSubCategoryIds: number[] = masterData.contestCategories
      .map((mc) => {
        const allSubcategoriesIncluded = mc.subcategories.every((msc) =>
          condition.subcategoryIds.includes(msc.id)
        );
        if (allSubcategoriesIncluded) {
          return [];
        }
        return mc.subcategories
          .filter((msc) => condition.subcategoryIds.includes(msc.id))
          .map((msc) => msc.id);
      })
      .flat();
    router.push(
      ROUTE.contestList(
        generateContestListUrlQuery({
          ...condition,
          subcategoryIds: newSubCategoryIds,
        })
      )
    );
  };

  const handleClickLogin = () => {
    menuModalState.close();
    signupModalState.close();
    loginModalState.open();
  };

  const handleClickSignup = () => {
    menuModalState.close();
    loginModalState.close();
    signupModalState.open();
  };

  return (
    <header className={clsx(isFixed && 'tw-pt-[108px] md:tw-pt-[140px]')}>
      <div
        className={clsx(
          'tw-w-full tw-h-[108px] md:tw-h-[140px]',
          isFixed && 'tw-fixed tw-top-0 tw-inset-x-0 tw-z-banner'
        )}
      >
        <div
          className={clsx(
            'tw-h-[48px] md:tw-h-[80px] tw-bg-white tw-border-b tw-border-gray-200 tw-flex tw-items-center'
          )}
        >
          <Container>
            {/* SP */}
            {customHeaderLink === undefined ? (
              <div className="tw-px-4 tw-flex tw-items-center tw-justify-between md:tw-hidden">
                <div>
                  <button
                    type="button"
                    aria-label="公募を探す"
                    className={clsx(
                      'tw-flex tw-flex-col tw-items-center tw-space-y-1',
                      'hover:tw-opacity-70 tw-duration-200 tw-transition'
                    )}
                    onClick={handleClickContestSearch}
                  >
                    <IconSearch />
                  </button>
                  <ContestSearchModal
                    isOpen={contestSearchModalState.isOpen}
                    onClose={contestSearchModalState.close}
                    onClickDetailedSearch={handleClickContestDetailedSearch}
                  />
                  <ContestDetailedSearchModal
                    isOpen={contestDetailedSearchModalState.isOpen}
                    onSearch={handleDetailedSearch}
                    onClose={contestDetailedSearchModalState.close}
                  />
                </div>
                <h1 className={clsx('tw-flex tw-items-center')}>
                  <AnchorLink
                    href={ROUTE.top()}
                    withDefaultHoverBehavior={false}
                  >
                    <>
                      <BrandLogo
                        className={clsx(
                          'lg:tw-hidden',
                          '!tw-w-[78px] !tw-h-[18px]'
                        )}
                      />
                      <span className="tw-block tw-w-[1px] tw-h-[1px] tw-overflow-hidden">
                        {SITE.NAME}
                      </span>
                    </>
                  </AnchorLink>
                </h1>
                <button
                  type="button"
                  aria-label="メニュー"
                  className={clsx(
                    'tw-flex tw-flex-col tw-items-center tw-space-y-1',
                    'hover:tw-opacity-70 tw-duration-200 tw-transition'
                  )}
                  onClick={menuModalState.open}
                >
                  <IconMenu boxSize="1.5em" />
                </button>
                {cartCount !== undefined && (
                  <div className="tw-absolute tw-top-1 tw-right-0">
                    <NotificationBadge count={cartCount} />
                  </div>
                )}
              </div>
            ) : (
              <div className={clsx('md:tw-hidden')}>
                <AnchorLink
                  href={customHeaderLink.href}
                  className={clsx(
                    'tw-px-spContentSide tw-flex tw-items-center tw-gap-x-2'
                  )}
                >
                  <IconChevronLeft boxSize="24px" />
                  <h1
                    className={clsx(
                      'tw-text-head16 tw-font-bold tw-line-clamp-1'
                    )}
                  >
                    {customHeaderLink.label}
                  </h1>
                </AnchorLink>
              </div>
            )}

            {/* PC */}
            <div className="tw-hidden md:tw-flex md:tw-justify-between md:tw-px-6 lg:tw-mx-auto lg:tw-px-0 lg:tw-max-w-none">
              <h1 className={clsx('tw-flex tw-items-center')}>
                <AnchorLink href={ROUTE.top()} withDefaultHoverBehavior={false}>
                  <>
                    <span className={clsx('tw-hidden md:tw-inline')}>
                      <BrandLogo width={118} />
                    </span>
                    <span className="tw-block tw-w-[1px] tw-h-[1px] tw-overflow-hidden">
                      {SITE.NAME}
                    </span>
                  </>
                </AnchorLink>
              </h1>
              <div
                className={clsx(
                  'tw-hidden md:tw-flex md:tw-items-center md:tw-space-x-4'
                )}
              >
                <div className="md:tw-flex md:tw-space-x-2">
                  <ContestSearchMenu />
                  <AnchorLink
                    href={ROUTE.student('all')}
                    className={clsx(
                      ' tw-h-11 tw-px-2 tw-flex tw-items-center tw-gap-x-2 tw-rounded-[8px]',
                      'hover:tw-bg-white/30 tw-transition tw-duration-200'
                    )}
                  >
                    <>
                      <IconWork />
                      <span className={clsx('tw-text-ui16 tw-font-bold')}>
                        学生向け
                      </span>
                    </>
                  </AnchorLink>
                  <AnchorLink
                    href={ROUTE.articleTop()}
                    className={clsx(
                      ' tw-h-11 tw-px-2 tw-flex tw-items-center tw-gap-x-2 tw-rounded-[8px]',
                      'hover:tw-bg-white/30 tw-transition tw-duration-200'
                    )}
                  >
                    <>
                      <IconArticle />
                      <span className={clsx('tw-text-ui16 tw-font-bold')}>
                        記事を読む
                      </span>
                    </>
                  </AnchorLink>
                </div>

                <div className={clsx('md:tw-w-40 lg:tw-w-60')}>
                  <GoogleSearchBar theme="outline" />
                </div>

                <div className={clsx('tw-flex tw-items-center tw-gap-x-4')}>
                  {creator && (
                    <a
                      href={ROUTE.tsukuLogTop()}
                      className={clsx(
                        'tw-flex tw-flex-col tw-items-center tw-space-y-1',
                        'hover:tw-opacity-70 tw-duration-200 tw-transition'
                      )}
                    >
                      <IconTsukuLog boxSize="24px" />
                      <span className={clsx('tw-text-ui10 tw-font-bold')}>
                        つくログ
                      </span>
                    </a>
                  )}

                  {creator ? (
                    <a
                      href={ROUTE.mypageTop()}
                      className={clsx(
                        'tw-flex tw-flex-col tw-items-center tw-space-y-1',
                        'hover:tw-opacity-70 tw-duration-200 tw-transition'
                      )}
                    >
                      <div
                        className={clsx(
                          'tw-h-6 tw-w-6 tw-rounded-circle tw-overflow-hidden'
                        )}
                      >
                        {creator.profileImage?.url ? (
                          <Image
                            src={creator.profileImage?.url}
                            alt=""
                            objectFit="cover"
                          />
                        ) : (
                          <IconDefaultProfile boxSize="24" />
                        )}
                      </div>
                      <span className={clsx('tw-text-ui10 tw-font-bold')}>
                        マイページ
                      </span>
                    </a>
                  ) : (
                    <div>
                      <button
                        type="button"
                        onClick={loginModalState.open}
                        className={clsx(
                          'tw-flex tw-flex-col tw-items-center tw-space-y-1',
                          'hover:tw-opacity-70 tw-duration-200 tw-transition'
                        )}
                      >
                        <IconMyPage boxSize="1.5em" />
                        <span className={clsx('tw-text-ui10 tw-font-bold')}>
                          ログイン
                        </span>
                      </button>
                    </div>
                  )}

                  <div className={clsx('tw-relative')}>
                    <button
                      type="button"
                      onClick={menuModalState.open}
                      className={clsx(
                        'tw-flex tw-flex-col tw-items-center tw-space-y-1',
                        'hover:tw-opacity-70 tw-duration-200 tw-transition'
                      )}
                    >
                      <IconMenu boxSize="24px" />
                      <span className={clsx('tw-text-ui10 tw-font-bold')}>
                        メニュー
                      </span>
                    </button>

                    {cartCount !== undefined && (
                      <div className="tw-absolute -tw-top-1 tw-right-0">
                        <NotificationBadge count={cartCount} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div
          className={clsx(
            'tw-h-[60px] tw-bg-gray-100 tw-overflow-x-hidden tw-border-b tw-border-gray-200 tw-flex tw-items-center'
          )}
        >
          <Container>
            <div
              className={clsx(
                'tw-pl-spContentSide tw-flex tw-items-center tw-justify-between',
                'md:tw-px-6 lg:tw-px-0'
              )}
            >
              <div className={clsx('tw-w-full md:tw-flex-1')}>
                <LinkButtonSlider items={categoryItems} itemShift={5} />
              </div>
              <div
                className={clsx(
                  'tw-ml-4 tw-hidden lg:tw-flex tw-items-center tw-gap-4'
                )}
              >
                <TextLink href={ROUTE.magazineLatest()}>
                  <strong>{ROUTE_NAME.magazineLatest}</strong>
                </TextLink>
                <TextLink href={ROUTE.school()} isBlank>
                  <strong>{ROUTE_NAME.school}</strong>
                </TextLink>
                <ButtonLink
                  theme="fill"
                  size="sm"
                  href={ROUTE.company()}
                  isBlock={false}
                >
                  {ROUTE_NAME.company}
                </ButtonLink>
              </div>
            </div>
          </Container>
        </div>
      </div>
      {/* Common */}
      <GlobalMenuModal
        creator={
          creator
            ? filter(GlobalMenuModalCreatorsFragmentDoc, creator)
            : undefined
        }
        isOpen={menuModalState.isOpen}
        onClose={menuModalState.close}
        onClickLogin={handleClickLogin}
        onClickSignup={handleClickSignup}
      />
      <AuthLoginModal
        isOpen={loginModalState.isOpen}
        onClose={loginModalState.close}
        onClickSignup={handleClickSignup}
      />
      <AuthSignupModal
        isOpen={signupModalState.isOpen}
        onClose={signupModalState.close}
        onClickLogin={handleClickLogin}
      />
    </header>
  );
};
