import { clsx } from 'clsx';

import type { InputSelectSingleProps, InputSelectValue } from './type';
import type { ChangeEventHandler } from 'react';

import { IconChevronDown } from '@/components/icons';
import { INPUT_CLASS_NAMES } from '@/components/ui/input/const';

export const InputSelectSingle = <
  Value extends InputSelectValue = InputSelectValue
>({
  inputRef,
  id,
  'aria-labelledby': ariaLabelledby,
  name,
  autoComplete,
  placeholder,
  disabled = false,
  value = '',
  options,
  theme = 'base',
  size = 'md',
  rounded = 'base',
  roundedDirection = {
    topLeft: true,
    topRight: true,
    bottomLeft: true,
    bottomRight: true,
  },
  state,
  onChange,
  onFocus,
  onBlur,
}: InputSelectSingleProps<Value>) => {
  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (e.target.selectedIndex === 0) {
      onChange(undefined);
    } else {
      const selectedOption = options.at(e.target.selectedIndex - 1);
      onChange(selectedOption?.value);
    }
  };

  return (
    <div className={clsx('tw-relative')}>
      <select
        ref={inputRef}
        id={id}
        aria-labelledby={ariaLabelledby}
        name={name}
        autoComplete={autoComplete}
        disabled={disabled}
        value={value}
        className={clsx(
          INPUT_CLASS_NAMES({ theme, size, rounded, roundedDirection, state }),
          'tw-pr-10 tw-appearance-none enabled:tw-cursor-pointer'
        )}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <option
          key="_placeholder"
          value={undefined}
          className={clsx(placeholder === undefined && 'tw-hidden')}
        >
          {placeholder}
        </option>
        {options.map(({ label, value, disabled }, i) => (
          <option key={`${i}_${value}`} value={value} disabled={disabled}>
            {label}
          </option>
        ))}
      </select>
      <div
        className={clsx(
          'tw-absolute tw-top-1/2 tw-right-4 tw--translate-y-1/2 tw-pointer-events-none'
        )}
      >
        <IconChevronDown boxSize="14px" />
      </div>
    </div>
  );
};
