import {
  format,
  addDays,
  addYears,
  differenceInDays,
  isWithinInterval,
  isAfter,
  lastDayOfMonth,
  getYear,
  getMonth,
  getDate,
} from 'date-fns';
import { ja } from 'date-fns/locale';

export const formatDate = (
  date?: string | number | Date | null,
  template = 'yyyy-MM-dd hh:mm'
) => {
  if (date == null) return undefined;
  if (!isValidDate(date)) return 'Invalid Date';

  return format(new Date(date), template, {
    locale: ja,
  });
};

export const isValidDate = (dateStr: string | number | Date): boolean => {
  try {
    const d = new Date(dateStr);
    return !Number.isNaN(d.getDate());
  } catch (_) {
    return false;
  }
};

export const addYear = (date: string | number | Date, yearCount: number) => {
  return addYears(new Date(date), yearCount);
};

/**
 * dayCount分日数を足したDateを取得する
 */
export const addDaysToDate = (
  date: string | number | Date,
  dayCount: number
) => {
  return addDays(new Date(date), dayCount);
};

export const getDifferenceInDays = (
  dateLeft: string | number | Date,
  dateRight: string | number | Date
) => differenceInDays(new Date(dateLeft), new Date(dateRight));

export const isWithinPastDays = (
  targetData: string | number | Date,
  pastDayCount: number
) => {
  if (!Number.isInteger(pastDayCount))
    throw new Error('day count is not integer');
  if (pastDayCount < 0) throw new Error('day count is negative');

  return isWithinInterval(new Date(targetData), {
    start: addDaysToDate(new Date(), -1 * pastDayCount),
    end: new Date(),
  });
};

export const checkIsAfter = (date: Date, target: Date) => isAfter(date, target);

export const getLastDayOfMonth = (date: Date) => lastDayOfMonth(date);

export const getThisYear = () => {
  return getYear(new Date());
};

export const parseDateStr = (
  dateStr: `${number}${number}${number}${number}-${number}${number}-${number}${number}`
) => {
  const date = new Date(dateStr);
  return {
    year: getYear(date),
    month: getMonth(date) + 1,
    date: getDate(date),
  };
};

export const isRecentDate = (date: Date) =>
  isAfter(date, addDaysToDate(new Date(), -7));
