import { clsx } from 'clsx';

import type { ButtonLinkProps } from './type';

export const BUTTON_LINK_THEME_CLASS_NAMES = ({
  theme,
  disabled,
}: Pick<ButtonLinkProps, 'theme' | 'disabled'>) => {
  switch (theme) {
    case 'fill': {
      return clsx(
        !disabled
          ? 'hover:tw-bg-primary-600'
          : 'tw-bg-gray-300 tw-text-gray-500'
      );
    }
    case 'outline': {
      return clsx(!disabled ? 'hover:tw-bg-gray-100' : 'tw-text-gray-500');
    }
    case 'text': {
      return clsx(!disabled ? 'hover:tw-opacity-70' : 'tw-text-gray-500');
    }
    default:
      return '';
  }
};
