import { ROUTE } from '@/constants';

export const MYPAGE_MENUS: Array<{
  label: string;
  href: string;
}> = [
  {
    label: 'マイページトップ',
    href: ROUTE.mypageTop(),
  },
  {
    label: 'お気に入り',
    href: ROUTE.favoriteTop(),
  },
  {
    label: '購入履歴',
    href: ROUTE.orderHistories(),
  },
  {
    label: '電子版を読む',
    href: ROUTE.digitalMagazines(),
  },
  {
    label: 'プロフィール設定',
    href: ROUTE.profileSetting(),
  },
  {
    label: 'アカウント設定・定期購読設定',
    href: ROUTE.accountSetting(),
  },
];
