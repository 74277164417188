import { clsx } from 'clsx';

import type { InputCheckboxGroupProps } from './type';
import type { InputValue } from '@/types/ui';

import { InputCheckbox } from '@/components/ui';

import { LIST_CLASS_NAMES } from './const';

export const InputCheckboxGroup = <Value extends InputValue = InputValue>({
  inputRef,
  id,
  options,
  disabled = false,
  direction = 'row',
  selectedValues,
  theme = 'base',
  size = 'md',
  state,
  onChange,
  onFocus,
  onBlur,
}: InputCheckboxGroupProps<Value>) => {
  const onChangeHandler = (value: Value) => {
    if (selectedValues === undefined) {
      onChange([value]);
    } else if (selectedValues?.includes(value)) {
      onChange(selectedValues.filter((v) => v !== value));
    } else {
      onChange([...selectedValues, value]);
    }
  };

  return (
    <fieldset ref={inputRef} id={id} onFocus={onFocus} onBlur={onBlur}>
      <ul className={LIST_CLASS_NAMES({ direction })}>
        {options.map((option, i) => (
          <li key={`${i}_${option.value}`} className={clsx('tw-flex-none')}>
            <InputCheckbox
              disabled={!!disabled || !!option.disabled}
              theme={theme}
              size={size}
              state={state}
              label={option.label}
              withLabel={true}
              value={!!selectedValues?.includes(option.value)}
              isIndeterminate={false}
              onChange={() => onChangeHandler(option.value)}
            />
          </li>
        ))}
      </ul>
    </fieldset>
  );
};
