import type { IconBaseProps } from '@/components/icons/type';

export const IconChevronLeft = ({ boxSize = '1.5em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 4.60909L15.4833 3L7 12L15.4833 21L17 19.3909L10.0334 12L17 4.60909Z"
        fill="currentColor"
      />
    </svg>
  );
};
