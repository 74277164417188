import { clsx } from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';

import type { LinkButtonSliderProps } from './type';

import { NavigationButton } from './NavigationButton';
import { Slider } from './Slider';

export const LinkButtonSlider = ({
  items,
  itemShift,
  theme = 'white',
}: LinkButtonSliderProps) => {
  const [offset, setOffset] = useState(0);

  const [showsLeftNav, setShowsLeftNav] = useState(false);
  const [showsRightNav, setShowsRightNav] = useState(true);

  const handlePrev = useCallback(() => {
    setOffset(Math.max(0, offset - Math.floor(itemShift)));
  }, [itemShift, offset]);

  const handleNext = useCallback(() => {
    setOffset(Math.min(items.length - 1, offset + Math.floor(itemShift)));
  }, [itemShift, items.length, offset]);

  const handleLeftEnd = useCallback((on: boolean) => {
    setShowsLeftNav(!on);
  }, []);

  const handleRightEnd = useCallback((on: boolean) => {
    setShowsRightNav(!on);
  }, []);

  useEffect(() => {
    setOffset(Math.max(0, items.findIndex((i) => i.isActive) - 1));
  }, [items]);

  return (
    <div className={clsx('tw-relative')}>
      <Slider
        className={clsx('md:tw-hidden')}
        items={items}
        offset={offset}
        spaceBetween={6}
        theme={theme}
      />
      <Slider
        className={clsx('tw-hidden md:tw-flex')}
        items={items}
        offset={offset}
        spaceBetween={6}
        theme={theme}
        onLeftEnd={handleLeftEnd}
        onRightEnd={handleRightEnd}
      />
      <NavigationButton
        direction="left"
        visible={showsLeftNav}
        theme={theme}
        className={clsx('tw-hidden md:tw-flex tw-absolute tw-top-0 tw-left-0')}
        onClick={handlePrev}
      />
      <NavigationButton
        direction="right"
        theme={theme}
        visible={showsRightNav}
        className={clsx('tw-hidden md:tw-flex tw-absolute tw-top-0 tw-right-0')}
        onClick={handleNext}
      />
    </div>
  );
};
