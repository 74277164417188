import type { IconBaseProps } from '@/components/icons/type';

export const IconChevronDown = ({ boxSize = '1.5em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3909 7L21 8.51671L12 17L3 8.51671L4.60909 7L12 13.9666L19.3909 7Z"
        fill="currentColor"
      />
    </svg>
  );
};
