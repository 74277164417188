import type { ImageProps } from './type';

import { BREAKPOINTS } from '@/constants';

export const generateSizes = (size: ImageProps['size']) => {
  if (size === undefined) return undefined;
  let sizes: string[] = [];
  if (size.sp !== undefined) {
    sizes = [...sizes, `(max-width: ${BREAKPOINTS.md}px) ${size.sp}`];
  }
  if (size.tablet !== undefined) {
    sizes = [...sizes, `(max-width: ${BREAKPOINTS.lg}px) ${size.tablet}`];
  }
  if (size.pc !== undefined) {
    sizes = [...sizes, size.pc];
  }

  return sizes.length > 0 ? sizes.join(', ') : undefined;
};
