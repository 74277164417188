import { Transition } from '@headlessui/react';
import { clsx } from 'clsx';

import type { AccordionProps } from './type';

import { useDisclosure } from '@/hooks/utils';

import { sizeClassName, borderClassName } from './const';

export const Accordion = ({
  children,
  initialOpenState = false,
  stickToTop = false,
  label = '',
  border = 'bottom',
  size = 'md',
}: AccordionProps) => {
  const { isOpen, toggle } = useDisclosure(initialOpenState);

  return (
    <div>
      <button
        type="button"
        className={clsx(
          'tw-w-full tw-flex tw-flex-wrap tw-items-center tw-justify-between',
          sizeClassName[size],
          borderClassName[border],
          'tw-border-gray-200',
          stickToTop && 'tw-sticky -tw-top-1 tw-bg-white'
        )}
        onClick={toggle}
      >
        <span className={clsx('tw-text-ui15 tw-font-bold')}>{label}</span>
        {isOpen ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="10" fill="#1C1C1C" />
            <rect x="7" y="11" width="10" height="2" fill="white" />
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="9.5" fill="#1C1C1C" stroke="#1C1C1C" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7Z"
              fill="white"
            />
          </svg>
        )}
      </button>

      <Transition
        show={isOpen}
        enter="tw-transition-opacity tw-transition-transform tw-duration-60 tw-ease-[ease-out-expo]"
        enterFrom="tw-opacity-0 -tw-translate-y-5"
        enterTo="tw-opacity-100 tw-translate-y-0"
        leave="tw-transition-opacity tw-transition-transform tw-duration-120 tw-ease-[ease-out-expo]"
        leaveFrom="tw-opacity-100 tw-translate-y-0"
        leaveTo="tw-opacity-0 -tw-translate-y-10"
      >
        {children}
      </Transition>
    </div>
  );
};
