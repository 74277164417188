import type { IconBaseProps } from '@/components/icons/type';
import type { UrlObject } from 'url';

import {
  IconTop,
  IconSearch,
  IconArticle,
  IconFanClub,
  IconBook,
  IconCart,
  IconWork,
} from '@/components/icons/basics';
import { ROUTE, ROUTE_NAME } from '@/constants';

export const MENUS: Array<{
  label: string;
  href: string | UrlObject;
  icon: ({ boxSize }: IconBaseProps) => JSX.Element;
  notification: number;
  disabled: boolean;
}> = [
  {
    label: 'トップ',
    href: ROUTE.top(),
    icon: IconTop,
    notification: 0,
    disabled: false,
  },
  {
    label: ROUTE_NAME.contestSearch,
    href: ROUTE.contestSearch(),
    icon: IconSearch,
    notification: 0,
    disabled: false,
  },
  {
    label: '学生向け',
    href: ROUTE.student('all'),
    icon: IconWork,
    notification: 0,
    disabled: false,
  },
  {
    label: '記事を読む',
    href: ROUTE.articleTop(),
    icon: IconArticle,
    notification: 0,
    disabled: false,
  },
  {
    label: ROUTE_NAME.service,
    href: ROUTE.service(),
    icon: IconFanClub,
    notification: 0,
    disabled: false,
  },
  {
    label: ROUTE_NAME.magazineLatest,
    href: ROUTE.magazineLatest(),
    icon: IconBook,
    notification: 0,
    disabled: false,
  },
  {
    label: 'カート',
    href: ROUTE.cart,
    icon: IconCart,
    notification: 0,
    disabled: false,
  },
];

export const SUB_MENUS = [
  { label: ROUTE_NAME.company, href: ROUTE.company(), isExternal: false },
  { label: ROUTE_NAME.school, href: ROUTE.school(), isExternal: true },
  { label: ROUTE_NAME.contact, href: ROUTE.contact(), isExternal: false },
  { label: ROUTE_NAME.sitePolicy, href: ROUTE.sitePolicy(), isExternal: false },
  { label: ROUTE_NAME.tokutei, href: ROUTE.tokutei(), isExternal: false },
  { label: ROUTE_NAME.privacy, href: ROUTE.privacy(), isExternal: false },
];
