import { clsx } from 'clsx';

import type { NavigationButtonProps } from './type';

import { IconOvalArrowRight } from '@/components/icons';
import { BUTTON_THEME_CLASS_NAME } from '@/components/ui/slider/LinkButtonSlider/NavigationButton/const';

export const NavigationButton = ({
  direction,
  visible,
  theme,
  onClick,
  className,
}: NavigationButtonProps) => {
  return (
    <button
      className={clsx(
        'tw-shrink-0 tw-w-12 tw-h-full tw-flex tw-items-center tw-pl-6',
        'tw-transition-opacity tw-duration-300 tw-ease-in',
        direction === 'left' && 'tw-rotate-180',
        !visible && 'tw-pointer-events-none tw-opacity-0',
        BUTTON_THEME_CLASS_NAME[theme],
        className
      )}
      onClick={onClick}
    >
      <IconOvalArrowRight />
    </button>
  );
};
