import { clsx } from 'clsx';
import { useController } from 'react-hook-form';

import type { FormFieldCheckboxGroupProps } from './type';
import type { InputCheckboxGroupProps } from '@/components/ui/input/InputCheckboxGroup/type';

import { FormFieldWrapper, InputCheckboxGroup } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldCheckboxGroup = ({
  name,
  control,
  shouldUnregister,
  defaultValue,
  withMarginTop = false,
  onAfterChange,
  ...props
}: FormFieldCheckboxGroupProps) => {
  const {
    field: { ref, onChange, ...field },
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });
  const { wrapProps, rest: inputProps } = extractWrapperProps({
    ...props,
    name,
  });

  const handleChange: InputCheckboxGroupProps['onChange'] = (value) => {
    onChange(value);
    if (onAfterChange !== undefined) {
      onAfterChange();
    }
  };

  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <div className={clsx(withMarginTop && 'tw-mt-3')}>
        <InputCheckboxGroup
          data-testid={wrapProps.id}
          id={wrapProps.id}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          inputRef={ref}
          selectedValues={field.value}
          state={error !== undefined ? 'error' : undefined}
          onChange={handleChange}
          {...inputProps}
          {...field}
        />
      </div>
    </FormFieldWrapper>
  );
};
