import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';

import type { SliderProps } from './types';

import { AnchorLink } from '@/components/ui/link';
import { combinedRefs } from '@/components/ui/slider/LinkButtonSlider/Slider/util';

import { LINK_BUTTON_THEME_CLASS_NAME } from './const';

export const Slider = ({
  className,
  items,
  offset,
  theme,
  spaceBetween,
  onLeftEnd,
  onRightEnd,
}: SliderProps) => {
  const [translateX, setTranslateX] = useState(0);
  const [minTranslateX, setMinTranslateX] = useState(0);

  const sliderRef = useRef<HTMLDivElement>(null);
  const firstItemRef = useRef<HTMLAnchorElement>(null);
  const offsetItemRef = useRef<HTMLAnchorElement>(null);
  const lastItemRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (sliderRef.current && firstItemRef.current && lastItemRef.current) {
      const sliderRect = sliderRef.current.getBoundingClientRect();
      const firstRect = firstItemRef.current.getBoundingClientRect();
      const lastItemRect = lastItemRef.current.getBoundingClientRect();
      const itemsWidth = lastItemRect.right - firstRect.left;
      const sliderWidth = sliderRect.right - sliderRect.left;
      setMinTranslateX(Math.min(0, sliderWidth - itemsWidth));
    }
  }, [items]);

  useEffect(() => {
    if (firstItemRef.current && offsetItemRef.current) {
      const firstItemRect = firstItemRef.current.getBoundingClientRect();
      const offsetItemRect = offsetItemRef.current.getBoundingClientRect();
      setTranslateX(
        Math.max(minTranslateX, firstItemRect.left - offsetItemRect.left)
      );
    }
  }, [items, offset, minTranslateX]);

  useEffect(() => {
    onLeftEnd && onLeftEnd(translateX === 0);
  }, [items, onLeftEnd, translateX]);

  useEffect(() => {
    onRightEnd && onRightEnd(translateX === minTranslateX);
  }, [items, onRightEnd, translateX, minTranslateX]);

  const itemRef = (index: number) => {
    const refs = [];
    if (index === 0) refs.push(firstItemRef);
    if (index === offset) refs.push(offsetItemRef);
    if (index === items.length - 1) refs.push(lastItemRef);
    return combinedRefs(...refs);
  };

  useEffect(() => {
    sliderRef.current?.scrollTo({ left: -translateX, behavior: 'smooth' });
  }, [translateX]);

  return (
    <div
      ref={sliderRef}
      className={clsx(
        'tw-flex tw-w-full tw-overflow-x-scroll tw-hidden-scrollbar md:tw-overflow-hidden tw-border-box tw-py-1',
        className
      )}
      style={{ gap: `${spaceBetween}px` }}
    >
      {items.map((item, index) => (
        <AnchorLink
          ref={itemRef(index)}
          key={index}
          href={item.href}
          withDefaultHoverBehavior={false}
          className={clsx(
            'tw-select-none tw-rounded-full tw-shrink-0 tw-ease-out tw-text-ui12 tw-leading-none tw-font-bold tw-px-3 tw-pt-3.5 tw-pb-[13px] hover:tw-cursor-pointer',
            item.isActive
              ? 'tw-bg-black tw-text-white tw-pointer-events-none'
              : LINK_BUTTON_THEME_CLASS_NAME[theme]
          )}
        >
          <div className={clsx('tw-w-full')}>{item.text}</div>
        </AnchorLink>
      ))}
    </div>
  );
};
