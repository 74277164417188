import clsx from 'clsx';
import { Fragment, useCallback, useEffect } from 'react';
import { useWatch, useController } from 'react-hook-form';

import type { FormFieldContestCategoryProps } from './type';

import {
  FormFieldCheckboxGroup,
  FormFieldWrapper,
  InputCheckbox,
} from '@/components/ui';
import { masterData } from '@/data/master';
import { generateOptions } from '@/utils/ui';

export const FormFieldContestCategory = ({
  control,
  onAfterChange,
}: FormFieldContestCategoryProps) => {
  const {
    field: { onChange },
    fieldState: { error },
  } = useController({ control, name: 'subcategoryIds' });

  const {
    field: { ref, onChange: onChangeCategory },
  } = useController({ control, name: 'categoryIds' });
  const watchedCategoryIds = useWatch({ control, name: 'categoryIds' });
  const watchedSubCategoryIds = useWatch({ control, name: 'subcategoryIds' });

  const checkIsAllSubCategorySelected = useCallback(
    (categoryId: number) => {
      const targetCategory = masterData.contestCategories.find(
        (category) => category.id === categoryId
      );
      if (targetCategory === undefined) return false;
      return targetCategory.subcategories.every(({ id }) =>
        watchedSubCategoryIds.includes(id)
      );
    },
    [watchedSubCategoryIds]
  );

  const checkIsSomeSubCategorySelected = useCallback(
    (categoryId: number) => {
      const isAllSelected = checkIsAllSubCategorySelected(categoryId);
      if (isAllSelected) return false;

      const targetCategory = masterData.contestCategories.find(
        (category) => category.id === categoryId
      );
      if (targetCategory === undefined) return false;
      return targetCategory.subcategories.some(({ id }) =>
        watchedSubCategoryIds.includes(id)
      );
    },
    [checkIsAllSubCategorySelected, watchedSubCategoryIds]
  );

  const handleCheckCategory = (categoryId: number) => {
    const targetCategory = masterData.contestCategories.find(
      (category) => category.id === categoryId
    );
    if (targetCategory === undefined) return;

    if (checkIsAllSubCategorySelected(categoryId)) {
      const filteredSubCategoryIds = watchedSubCategoryIds.filter(
        (id) => !targetCategory.subcategories.map((s) => s.id).includes(id)
      );
      const filteredCategoryIds = watchedCategoryIds.filter(
        (id) => id !== categoryId
      );
      onChange(filteredSubCategoryIds);
      onChangeCategory(filteredCategoryIds);
    } else {
      const newSubCategoryIds = Array.from(
        new Set([
          ...watchedSubCategoryIds,
          ...targetCategory.subcategories.map((s) => s.id),
        ])
      );
      const newCategoryIds = Array.from(
        new Set([...watchedCategoryIds, categoryId])
      );
      onChange(newSubCategoryIds);
      onChangeCategory(newCategoryIds);
    }
    if (onAfterChange !== undefined) {
      onAfterChange();
    }
  };

  useEffect(() => {
    const newCategoryIds = masterData.contestCategories
      .map((mc) => {
        if (checkIsAllSubCategorySelected(mc.id)) {
          return mc.id;
        }
      })
      .filter((id) => id !== undefined);
    onChangeCategory(newCategoryIds);
    if (onAfterChange !== undefined) {
      onAfterChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIsAllSubCategorySelected]);

  useEffect(() => {
    if (watchedCategoryIds.length < 1) return;
    onChangeCategory(watchedCategoryIds);
    const newSubCategoryIds: number[] = masterData.contestCategories
      .map((mc) => {
        if (watchedCategoryIds.includes(mc.id)) {
          return mc.subcategories.map((s) => s.id);
        }
        return watchedSubCategoryIds;
      })
      .flat();
    onChange(newSubCategoryIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormFieldWrapper error={error} showLabel={false}>
        <div className={clsx('tw-space-y-4')}>
          {masterData.contestCategories.map((category) => (
            <Fragment key={category.id}>
              <InputCheckbox
                name="categoryIds"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                inputRef={ref}
                label={category.name}
                value={checkIsAllSubCategorySelected(category.id)}
                isIndeterminate={checkIsSomeSubCategorySelected(category.id)}
                onChange={() => handleCheckCategory(category.id)}
              />
              <div className={clsx('tw-pl-6')}>
                <FormFieldCheckboxGroup
                  name="subcategoryIds"
                  options={generateOptions(category.subcategories)}
                  control={control}
                  showLabel={false}
                  showError={false}
                />
              </div>
            </Fragment>
          ))}
        </div>
      </FormFieldWrapper>
    </>
  );
};
