import type { IconBaseProps } from '@/components/icons/type';

export const IconBook = ({ boxSize = '1.5em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 19.1186C9.31 17.8486 6.26 17.6586 3.46 18.5486C2.64 18.8086 2 18.4486 2 17.6186V6.11858C2 5.56858 2.42 4.90859 2.96 4.71859C5.89 3.64859 9.15 3.77859 12 5.11859V19.1186Z"
        stroke="#1C1C1C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.54 18.5488C17.74 17.6588 14.69 17.8488 12 19.1188V5.11879C14.85 3.77879 18.1 3.63879 21.04 4.71879C21.58 4.91879 22 5.56879 22 6.11879V17.6188C22 18.4488 21.36 18.8088 20.54 18.5488V18.5488Z"
        stroke="#1C1C1C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
