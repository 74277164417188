import { Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { useState, useCallback, Fragment, type MouseEventHandler } from 'react';
import { useLockedBody, useWindowSize } from 'usehooks-ts';

import {
  IconChevronRight,
  IconOvalArrowRight,
  IconSearch,
} from '@/components/icons';
import { AnchorLink } from '@/components/ui';
import { ROUTE } from '@/constants';
import { masterData } from '@/data/master';
import { useDisclosure } from '@/hooks/utils';
import { generateContestListUrlQuery } from '@/utils/features/contest';

export const ContestSearchMenu = () => {
  const { isOpen, open, close } = useDisclosure();

  useLockedBody(isOpen, 'root');

  const { width } = useWindowSize();

  const [buttonXPosition, setButtonXPosition] = useState(0);

  const handleButtonRef = useCallback(
    (node: HTMLButtonElement | null) => {
      if (node === null) return;
      const { x } = node.getBoundingClientRect();

      setButtonXPosition(x);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width]
  );

  const handleClickOverlay: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target === e.currentTarget) {
      close();
    }
  };

  return (
    <div className={clsx('tw-relative')}>
      <button
        ref={handleButtonRef}
        onClick={open}
        className={clsx(
          ' tw-h-11 tw-px-2 tw-flex tw-items-center tw-gap-x-2 tw-rounded-[8px]',
          'hover:tw-opacity-50 tw-transition tw-duration-200'
        )}
      >
        <IconSearch />
        <span className={clsx('tw-text-ui16 tw-font-bold')}>公募を探す</span>
      </button>

      <Transition
        as={Fragment}
        show={isOpen}
        enter={clsx('tw-transition-opacity tw-duration-200')}
        enterFrom={clsx('tw-opacity-0')}
        enterTo={clsx('tw-opacity-100')}
        leave={clsx('tw-transition-opacity tw-duration-200')}
        leaveFrom={clsx('tw-opacity-100')}
        leaveTo={clsx('tw-opacity-0')}
      >
        <div
          className={clsx('tw-fixed tw-inset-0 tw-overflow-y-auto tw-z-modal')}
        >
          <div
            className={clsx(
              'tw-absolute tw-w-full tw-bg-black/60 tw-flex tw-items-center tw-justify-center tw-min-h-screen'
            )}
            onClick={handleClickOverlay}
          />
          <div
            role="dialog"
            aria-modal
            className={clsx(
              'tw-absolute tw-top-[100px] tw-min-w-[440px] tw-bg-white tw-rounded-lg'
            )}
            style={{ left: `${buttonXPosition}px` }}
          >
            <div
              className={clsx(
                'tw-relative tw-pt-6 tw-px-6 tw-flex tw-items-center tw-justify-between tw-rounded-t-lg',
                'before:content-[""] before:tw-absolute before:tw--top-6 before:tw-left-8',
                'before:tw-w-6 before:tw-h-6 before:tw-border-[12px] before:tw-border-white before:tw-border-t-transparent before:tw-border-x-transparent'
              )}
            >
              <AnchorLink href={ROUTE.contestSearch()}>
                <div className={clsx('tw-flex tw-items-center tw-gap-x-2')}>
                  <span className={clsx('tw-text-primary-500')}>
                    <IconOvalArrowRight boxSize="32px" />
                  </span>
                  <span className={clsx('tw-text-head20 tw-font-bold')}>
                    公募を探す
                  </span>
                </div>
              </AnchorLink>
            </div>
            <ul
              className={clsx(
                'tw-p-6 tw-grid tw-grid-cols-3 tw-gap-x-10 tw-gap-y-6'
              )}
            >
              {masterData.contestCategories.map(({ id, name }) => (
                <li key={id}>
                  <AnchorLink
                    href={ROUTE.contestList(
                      generateContestListUrlQuery({ categoryIds: [id] })
                    )}
                    className={clsx('tw-flex tw-items-center tw-gap-x-1')}
                    onClickCapture={close}
                  >
                    <>
                      <span className={clsx('tw-text-gray-400')}>
                        <IconChevronRight boxSize="1em" />
                      </span>
                      <span className={clsx('tw-text-ui14')}>{name}</span>
                    </>
                  </AnchorLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Transition>
    </div>
  );
};
