import { clsx } from 'clsx';
import { Fragment, useCallback } from 'react';
import { useController, useWatch } from 'react-hook-form';

import type { FormFieldContestPrefectureProps } from './type';

import {
  FormFieldWrapper,
  InputCheckbox,
  FormFieldCheckboxGroup,
} from '@/components/ui';
import { NON_PREFECTURE_OPTION } from '@/constants/features/contest';
import { masterData } from '@/data/master';
import { generateOptions } from '@/utils/ui';

import { FIELD_NAME } from './const';

export const FormFieldContestPrefecture = ({
  control,
  onAfterChange,
}: FormFieldContestPrefectureProps) => {
  const {
    field: { ref, onChange },
    fieldState: { error },
  } = useController({ control, name: FIELD_NAME });

  const watchedPrefectureIds = useWatch({
    control,
    name: FIELD_NAME,
  });

  const checkIsAllRegionPrefecturesSelected = useCallback(
    (regionId: number) => {
      const targetRegion = masterData.regions.find(
        (region) => region.id === regionId
      );
      if (targetRegion === undefined) return false;
      return targetRegion.prefectures.every(({ id }) =>
        watchedPrefectureIds.includes(id)
      );
    },
    [watchedPrefectureIds]
  );

  const checkIsSomeRegionPrefecturesSelected = useCallback(
    (regionId: number) => {
      const isAllSelected = checkIsAllRegionPrefecturesSelected(regionId);
      if (isAllSelected) return false;

      const targetRegion = masterData.regions.find(
        (region) => region.id === regionId
      );
      if (targetRegion === undefined) return false;
      return targetRegion.prefectures.some(({ id }) =>
        watchedPrefectureIds.includes(id)
      );
    },
    [checkIsAllRegionPrefecturesSelected, watchedPrefectureIds]
  );

  const handleCheckAllRegionPrefectures = (regionId: number) => {
    const targetRegion = masterData.regions.find(
      (region) => region.id === regionId
    );
    if (targetRegion === undefined) return;

    if (checkIsAllRegionPrefecturesSelected(regionId)) {
      const filteredJobIds = watchedPrefectureIds.filter(
        (id) => !targetRegion.prefectures.map((p) => p.id).includes(id)
      );
      onChange(filteredJobIds);
    } else {
      const concatedJobIds = Array.from(
        new Set([
          ...watchedPrefectureIds,
          ...targetRegion.prefectures.map((p) => p.id),
        ])
      );
      onChange(concatedJobIds);
    }
    if (onAfterChange !== undefined) {
      onAfterChange();
    }
  };

  return (
    <FormFieldWrapper error={error} showLabel={false}>
      <div className={clsx('tw-space-y-4')}>
        <FormFieldCheckboxGroup
          name={FIELD_NAME}
          options={generateOptions([NON_PREFECTURE_OPTION])}
          control={control}
          showLabel={false}
          showError={false}
          onAfterChange={onAfterChange}
        />
        {masterData.regions.map((region) =>
          region.prefectures.length > 1 ? (
            <Fragment key={region.id}>
              <InputCheckbox
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                inputRef={ref}
                label={region.name}
                value={checkIsAllRegionPrefecturesSelected(region.id)}
                isIndeterminate={checkIsSomeRegionPrefecturesSelected(
                  region.id
                )}
                onChange={() => handleCheckAllRegionPrefectures(region.id)}
              />
              <div className={clsx('tw-pl-6')}>
                <FormFieldCheckboxGroup
                  name={FIELD_NAME}
                  options={generateOptions(region.prefectures)}
                  control={control}
                  showLabel={false}
                  showError={false}
                  onAfterChange={onAfterChange}
                />
              </div>
            </Fragment>
          ) : (
            <FormFieldCheckboxGroup
              key={region.id}
              name={FIELD_NAME}
              options={generateOptions(region.prefectures)}
              control={control}
              showLabel={false}
              showError={false}
              onAfterChange={onAfterChange}
            />
          )
        )}
      </div>
    </FormFieldWrapper>
  );
};
