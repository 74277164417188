import { clsx } from 'clsx';
import { filter } from 'graphql-anywhere';

import type { CommonLayoutProps } from './type';

import { GlobalHeader, GlobalSpFooter, GlobalFooter } from '@/components/app';
import {
  CommonLayoutClientMeDocument,
  CommonLayoutCreatorsFragmentDoc,
  GlobalHeaderCreatorsFragmentDoc,
  GlobalSpFooterCreatorsFragmentDoc,
} from '@/graphql/generated';
import { useQueryWrapper } from '@/libs/apollo';

const CommonLayoutForSSR = ({
  subHeader,
  creator,
  customHeaderLink,
  activeCategoryId,
  children,
}: CommonLayoutProps) => {
  return (
    <>
      <div
        className={clsx('tw-w-full tw-min-h-screen tw-flex tw-flex-col')}
        style={{ scrollbarGutter: 'stable' }}
      >
        <GlobalHeader
          creator={
            creator
              ? filter(GlobalHeaderCreatorsFragmentDoc, creator)
              : undefined
          }
          customHeaderLink={customHeaderLink}
          activeCategoryId={activeCategoryId}
        />

        {subHeader !== undefined && subHeader}

        {/* NOTE: React fragmentを親にして複数要素を入れられると崩れかねないので囲む */}
        <div className={clsx('tw-flex-1')}>{children}</div>

        <div className={clsx('tw-hidden tw-mt-24 md:tw-block')}>
          <GlobalFooter />
        </div>

        {creator && (
          <div className={clsx('tw-pb-14 md:tw-hidden')}>
            <div
              className={clsx('tw-fixed tw-bottom-0 tw-inset-x-0 tw-z-sticky')}
            >
              <GlobalSpFooter
                creator={filter(GlobalSpFooterCreatorsFragmentDoc, creator)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const CommonLayoutForCSR = (
  props: Omit<CommonLayoutProps, 'masterData' | 'creator'>
) => {
  const { data: me } = useQueryWrapper(CommonLayoutClientMeDocument);
  return (
    <CommonLayoutForSSR
      creator={
        me?.me?.creator
          ? filter(CommonLayoutCreatorsFragmentDoc, me.me.creator)
          : undefined
      }
      {...props}
    />
  );
};

export { CommonLayoutForSSR, CommonLayoutForCSR as CommonLayout };
