import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import { createElement, useMemo } from 'react';

import type { ImageMenu, IconMenu, GlobalSpFooterProps } from './type';

import {
  IconDefaultProfile,
  IconMyPage,
  IconTsukuLog,
} from '@/components/icons';
import { AnchorLink, Image as ProfileImage } from '@/components/ui';
import { ROUTE, ROUTE_NAME } from '@/constants';

export const GlobalSpFooter = ({ creator }: GlobalSpFooterProps) => {
  const router = useRouter();

  const navMenus = useMemo(() => {
    const menu: Array<ImageMenu | IconMenu> = [
      {
        type: 'icon',
        label: ROUTE_NAME.tsukuLogTop,
        href: ROUTE.tsukuLogTop(),
        icon: IconTsukuLog,
        isCurrent:
          router.asPath.startsWith(ROUTE.tsukuLogTop()) &&
          router.asPath !== ROUTE.profile(creator.id ?? -1) &&
          router.asPath !== ROUTE.profileFolloweeList(creator.id ?? -1) &&
          router.asPath !== ROUTE.profileFollowerList(creator.id ?? -1),
      },
      {
        type: 'image',
        label: ROUTE_NAME.profile,
        href: ROUTE.profile(creator.id ?? -1),
        imgSrc: creator.profileImage?.url,
        isCurrent:
          router.asPath === ROUTE.profile(creator.id ?? -1) ||
          router.asPath === ROUTE.profileFolloweeList(creator.id ?? -1) ||
          router.asPath === ROUTE.profileFollowerList(creator.id ?? -1),
      },
      {
        type: 'icon',
        label: ROUTE_NAME.mypageTop,
        href: ROUTE.mypageTop(),
        icon: IconMyPage,
        isCurrent: router.asPath.startsWith(ROUTE.mypageTop()),
      },
    ];
    return menu;
  }, [router, creator]);

  return (
    <nav
      aria-label="スマホ用固定メニュー"
      className={clsx('tw-bg-white', 'tw-border-t tw-border-gray-300')}
    >
      <ul className={clsx('tw-flex tw-items-center')}>
        {navMenus.map((menu) => (
          <li key={menu.label} className={clsx('tw-flex-1')}>
            <AnchorLink
              href={menu.href}
              className={clsx(
                'tw-h-14 tw-pt-2 tw-flex tw-flex-col tw-items-center tw-space-y-1'
              )}
            >
              <>
                <span
                  className={clsx(
                    menu.isCurrent ? 'tw-text-primary-500' : 'tw-text-gray-700'
                  )}
                >
                  {menu.type === 'image' && (
                    <span
                      className={clsx(
                        'tw-block tw-w-6 tw-h-6 tw-rounded-circle tw-overflow-hidden'
                      )}
                    >
                      {menu.imgSrc ? (
                        <ProfileImage
                          src={menu.imgSrc}
                          alt=""
                          objectFit="cover"
                          size={{ sp: '24px', tablet: '24px', pc: '24px' }}
                        />
                      ) : (
                        <IconDefaultProfile boxSize="24px" />
                      )}
                    </span>
                  )}
                  {menu.type === 'icon' &&
                    menu.icon &&
                    createElement(menu.icon, { boxSize: '24px' })}
                </span>
                <span
                  className={clsx(
                    'tw-text-ui10',
                    menu.isCurrent
                      ? 'tw-text-black tw-font-bold'
                      : 'tw-text-gray-700'
                  )}
                >
                  {menu.label}
                </span>
              </>
            </AnchorLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};
