import { useRouter } from 'next/router';
import { useEffect } from 'react';

import type { GoogleSearchBarProps } from './type';

import { SEARCH_ENGINE_ID } from '@/libs/gcse/const';

import { baseCss, themeCss } from './const';

export const GoogleSearchBar = ({
  theme = 'outline',
}: GoogleSearchBarProps) => {
  const { asPath, query } = useRouter();

  useEffect(() => {
    const script = document.createElement('script');

    try {
      script.src = `https://cse.google.com/cse.js?cx=${SEARCH_ENGINE_ID}`;
      script.async = true;

      document.body.appendChild(script);
    } catch (error) {
      console.error(error);
    }

    const style = document.createElement('style');
    style.textContent = `${baseCss}\n${themeCss[theme]}`;
    document.head.appendChild(style);

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(style);
    };
  }, [asPath, query, theme]);

  return <div className="gcse-search"></div>;
};
