import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import type { MyPageMenuListProps } from './type';

import { IconChevronRight } from '@/components/icons';
import { AnchorLink } from '@/components/ui';
import { ROUTE } from '@/constants';
import { MYPAGE_MENUS } from '@/constants/features/mypage';
import { MyPageMenuListLogoutDocument } from '@/graphql/generated.creator';
import { useToast } from '@/hooks/utils';
import { useMutationWrapper } from '@/libs/apollo';

import { sizeClassName } from './const';

export const MyPageMenuList = ({
  excludePaths = [],
  includeLogout = false,
  size = 'md',
}: MyPageMenuListProps) => {
  const router = useRouter();
  const { showToast } = useToast();

  const [logoutMutation] = useMutationWrapper(MyPageMenuListLogoutDocument, {
    refetchQueries: 'active',
    role: 'creator',
  });

  const menus = useMemo(() => {
    return MYPAGE_MENUS.filter(({ href }) => !excludePaths.includes(href));
  }, [excludePaths]);

  const handleLogout = () => {
    logoutMutation({
      onCompleted: () => {
        showToast('success', 'ログアウトしました');
        router.push(ROUTE.top());
      },
    });
  };
  return (
    <ul>
      {menus.map(({ href, label }) => (
        <li
          key={label}
          className={clsx(
            'tw-border-b tw-border-gray-300',
            !includeLogout && 'last-of-type:tw-border-b-0'
          )}
        >
          <AnchorLink
            className={clsx(
              'tw-py-4 tw-flex tw-items-center tw-justify-between',
              sizeClassName[size]
            )}
            href={href}
          >
            <span>{label}</span>
            <span className={clsx('tw-text-gray-400')}>
              <IconChevronRight boxSize="16px" />
            </span>
          </AnchorLink>
        </li>
      ))}
      {includeLogout && (
        <li>
          <button
            className={clsx(
              'tw-py-4 tw-w-full tw-flex tw-items-center tw-justify-between',
              'hover:tw-opacity-50 tw-transition tw-duration-200',
              sizeClassName[size]
            )}
            onClick={handleLogout}
          >
            <span>ログアウト</span>
            <span className={clsx('tw-text-gray-400')}>
              <IconChevronRight boxSize="16px" />
            </span>
          </button>
        </li>
      )}
    </ul>
  );
};
