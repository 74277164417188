import { clsx } from 'clsx';

import type { ContestCategorySearchModalProps } from './type';

import { IconChevronRight } from '@/components/icons';
import { HalfModal, Accordion, AnchorLink } from '@/components/ui';
import { ROUTE } from '@/constants';
import { masterData } from '@/data/master';
import { generateContestListUrlQuery } from '@/utils/features/contest';

export const ContestCategorySearchModal = ({
  isOpen,
  onClose,
}: ContestCategorySearchModalProps) => {
  return (
    <HalfModal
      isOpen={isOpen}
      title="カテゴリから探す"
      ariaLabel="カテゴリ検索メニュー"
      maxWidthPx={552}
      onClose={onClose}
    >
      <ul>
        {masterData.contestCategories.map((category) => (
          <li key={category.id}>
            <Accordion
              label={category.name}
              initialOpenState={false}
              stickToTop
            >
              <ul className={clsx('tw-text-ui15')}>
                <li key={`${category.id}-all`}>
                  <AnchorLink
                    href={ROUTE.contestList(
                      generateContestListUrlQuery({
                        categoryIds: [category.id],
                      })
                    )}
                    withDefaultHoverBehavior={false}
                    className={clsx(
                      'tw-h-11 tw-flex tw-items-center tw-space-x-1',
                      'hover:tw-opacity-70 tw-transition tw-duration-200'
                    )}
                  >
                    <>
                      <span className={clsx('tw-text-gray-400')}>
                        <IconChevronRight boxSize="16px" />
                      </span>
                      <span>{`すべての${category.name}`}</span>
                    </>
                  </AnchorLink>
                </li>
                {category.subcategories.map((subCategory) => (
                  <li key={subCategory.id}>
                    <AnchorLink
                      href={ROUTE.contestList(
                        generateContestListUrlQuery({
                          subcategoryIds: [subCategory.id],
                        })
                      )}
                      withDefaultHoverBehavior={false}
                      className={clsx(
                        'tw-h-11 tw-flex tw-items-center tw-space-x-1',
                        'hover:tw-opacity-70 tw-transition tw-duration-200'
                      )}
                    >
                      <>
                        <span className={clsx('tw-text-gray-400')}>
                          <IconChevronRight boxSize="16px" />
                        </span>
                        <span>{subCategory.name}</span>
                      </>
                    </AnchorLink>
                  </li>
                ))}
              </ul>
            </Accordion>
          </li>
        ))}
      </ul>
    </HalfModal>
  );
};
