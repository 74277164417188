import { clsx } from 'clsx';
import { useState, useCallback, useRef, type FocusEventHandler } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import type { InputDateProps } from './type';

import { IconCalendar } from '@/components/icons';
import { Calendar } from '@/components/ui';
import {
  INPUT_BASE_CLASS_NAMES,
  INPUT_THEME_CLASS_NAMES,
  INPUT_SIZE_CLASS_NAMES,
  INPUT_ROUNDED_CLASS_NAMES,
} from '@/components/ui/input/const';
import { DATE_FORMAT } from '@/constants/date';
import { useDisclosure } from '@/hooks/utils';
import { formatDate } from '@/utils/date';

const leftClasses = 'tw-left-0';
const rightClasses = 'tw-right-0';

export const InputDate = ({
  inputRef,
  id,
  name,
  placeholder = '年/月/日',
  disabled = false,
  value = '',
  dateFormat = DATE_FORMAT.yyyyMMdd,
  minDate,
  maxDate,
  theme = 'base',
  size = 'md',
  rounded = 'base',
  roundedDirection = {
    topLeft: true,
    topRight: true,
    bottomLeft: true,
    bottomRight: true,
  },
  calendarPosition,
  state,
  parseInput,
  onChange,
  onFocus,
  onBlur,
}: InputDateProps) => {
  const [calendarPositionOrigin, setCalendarPositionOrigin] =
    useState(leftClasses);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const { isOpen, open, close } = useDisclosure();

  useOnClickOutside(containerRef, close);

  const handleClick = useCallback(() => {
    open();
  }, [open]);

  const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      open();
      onFocus && onFocus(event);
    },
    [open, onFocus]
  );

  const handleChange: InputDateProps['onChange'] = useCallback(
    (value) => {
      onChange(value);
      close();
    },
    [close, onChange]
  );

  const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const hasClickedSelf =
        containerRef.current !== null
          ? containerRef.current.contains(event.relatedTarget)
          : false;
      if (hasClickedSelf) return;

      if (value === undefined) {
        handleChange(value);
        return;
      }
      const newDate = parseInput ? parseInput(value) : new Date(value ?? '');
      const isValidDate = !Number.isNaN(newDate.getTime());
      if (isValidDate) {
        handleChange(formatDate(newDate, dateFormat));
      } else {
        handleChange(undefined);
      }
      onBlur && onBlur(event);
    },
    [dateFormat, handleChange, onBlur, parseInput, value]
  );

  /** カレンダーUIの表示位置取得 */
  const getCalendarPositionClassName = useCallback(
    (calendarElement: HTMLDivElement) => {
      if (calendarElement === null || containerRef.current === null) return;

      if (calendarPosition !== undefined) {
        return setCalendarPositionOrigin(
          calendarPosition === 'left' ? leftClasses : rightClasses
        );
      }

      const calendarRect = calendarElement.getClientRects();
      const containerRect = containerRef.current.getClientRects();

      if (calendarRect.length < 1 || containerRect.length < 1) return;

      setCalendarPositionOrigin(
        calendarRect[0] &&
          containerRect[0] &&
          calendarRect[0].width < window.innerWidth - containerRect[0].left
          ? leftClasses
          : rightClasses
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div ref={containerRef} className={clsx('tw-relative')}>
      <input
        ref={inputRef}
        id={id}
        name={name}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        className={clsx(
          INPUT_THEME_CLASS_NAMES({ theme, state }),
          INPUT_SIZE_CLASS_NAMES({ size }),
          INPUT_ROUNDED_CLASS_NAMES({ rounded, roundedDirection }),
          INPUT_BASE_CLASS_NAMES(),
          'tw-pr-10'
        )}
        onClick={handleClick}
        onChange={(event) => handleChange(event.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <button
        type="button"
        className={clsx(
          'tw-absolute tw-right-4 tw-top-1/2 tw--translate-y-1/2',
          'tw-fill-gray-500',
          !disabled && 'group-hover:tw-fill-gray-700',
          isOpen && 'tw-fill-gray-700',
          'disabled:tw-cursor-not-allowed disabled:tw-fill-gray-300'
        )}
        disabled={disabled}
        onClick={handleClick}
      >
        <IconCalendar />
      </button>
      {isOpen && (
        <div
          ref={getCalendarPositionClassName}
          className={clsx(
            'tw-absolute tw-top-0 tw-translate-y-12 tw-z-tooltip',
            calendarPositionOrigin
          )}
        >
          <Calendar
            value={value}
            dateFormat={dateFormat}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};
