import { clsx } from 'clsx';

import type { Theme } from '../type';

export const BUTTON_THEME_CLASS_NAME: Record<Theme, string> = {
  white: clsx(
    'tw-bg-gradient-to-r tw-from-white/0 tw-via-gray-100 tw-to-gray-100'
  ),
  gray: clsx('tw-bg-gradient-to-r tw-from-white/0 tw-via-white tw-to-white'),
};
