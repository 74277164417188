import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import { useState, type FormEventHandler } from 'react';

import { IconSearch } from '@/components/icons';
import { ROUTE } from '@/constants';
import { generateContestListUrlQuery } from '@/utils/features/contest';

export const ContestKeywordSearchBar = () => {
  const router = useRouter();

  const [keyword, setKeyword] = useState('');

  const handleKeywordSearch: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    router.push(ROUTE.contestList(generateContestListUrlQuery({ keyword })));
  };

  return (
    <form
      className={clsx(
        'tw-w-full tw-h-12 tw-pr-1 tw-flex tw-items-center tw-bg-white tw-rounded-full',
        'tw-border tw-border-gray-300'
      )}
      onSubmit={handleKeywordSearch}
    >
      <input
        type="search"
        value={keyword}
        placeholder="キーワードから公募を探す"
        className={clsx(
          'tw-flex-1 tw-w-full tw-h-full tw-px-6 tw-bg-transparent tw-outline-none',
          'placeholder:tw-text-black/40'
        )}
        onChange={(e) => setKeyword(e.target.value)}
      />
      <button
        type="submit"
        aria-label="検索"
        className={clsx(
          'tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-text-white tw-rounded-circle',
          'hover:tw-opacity-70 tw-transition tw-duration-200'
        )}
      >
        <IconSearch />
      </button>
    </form>
  );
};
