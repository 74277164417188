import { clsx } from 'clsx';
import { H } from 'react-headings';

import type { ContestCategorySearchProps } from './type';

import { ContestCategorySearchModal } from '@/components/features/contest';
import { Button, AnchorLink } from '@/components/ui';
import { ROUTE } from '@/constants';
import { CATEGORY_ICON_MAP } from '@/constants/features/contest';
import { masterData } from '@/data/master';
import { useDisclosure } from '@/hooks/utils';
import { generateContestListUrlQuery } from '@/utils/features/contest';

export const ContestCategorySearch = ({
  titleSize = 'base',
}: ContestCategorySearchProps) => {
  const categorySearchModalState = useDisclosure();

  return (
    <>
      <div className={clsx('tw-flex tw-items-end')}>
        <div className={clsx('tw-w-3 tw-h-7 tw-rounded-tl-2xl tw-bg-white')} />
        <div
          className={clsx('tw-flex-1 tw-h-10 tw-bg-repeat-round')}
          style={{ backgroundImage: 'url("/images/ring.svg")' }}
        />
        <div className={clsx('tw-w-3 tw-h-7 tw-rounded-tr-2xl tw-bg-white')} />
      </div>
      <div
        className={clsx(
          'tw-relative tw-px-4 tw-pb-4 tw-bg-white tw-rounded-b-2xl'
        )}
      >
        <div className={clsx('tw-flex tw-items-center tw-justify-between')}>
          <H
            className={clsx(
              'tw-text-head20 tw-font-bold',
              titleSize === 'small' && 'tw-text-head16'
            )}
          >
            カテゴリから探す
          </H>
          <Button
            theme="outline"
            size="sm"
            isBlock={false}
            onClick={categorySearchModalState.open}
          >
            サブカテゴリ
          </Button>
          <ContestCategorySearchModal
            isOpen={categorySearchModalState.isOpen}
            onClose={categorySearchModalState.close}
          />
        </div>
        <ul
          className={clsx(
            'tw-mt-4 tw-grid tw-grid-cols-1 tw-gap-1 md:tw-grid-cols-3 md:tw-gap-2'
          )}
        >
          {masterData.contestCategories.map((category) => (
            <li
              key={`category-${category.id}`}
              className={clsx(
                'tw-border tw-border-gray-400 tw-rounded',
                'hover:tw-bg-gray-50 tw-transition tw-duration-200'
              )}
            >
              <AnchorLink
                href={ROUTE.contestList(
                  generateContestListUrlQuery({ categoryIds: [category.id] })
                )}
              >
                <span
                  className={clsx(
                    'tw-flex tw-h-12 tw-items-center tw-px-4 tw-text-ui14 tw-font-bold tw-space-x-2 tw-cursor-pointer'
                  )}
                >
                  <div className={clsx('tw-size-6')}>
                    {CATEGORY_ICON_MAP.has(category.id)
                      ? CATEGORY_ICON_MAP.get(category.id)
                      : null}
                  </div>
                  <span>{category.name}</span>
                </span>
              </AnchorLink>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
