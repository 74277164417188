import type { IconBaseProps } from '@/components/icons/type';

export const IconTsukuLog = ({ boxSize = '1.5em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 8.83V20C18 21.11 17.1 22 16 22H4C2.89 22 2 21.11 2 20V8C2 6.9 2.89 6 4 6H15.17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3894 15.44L7.85938 16.14L8.55937 12.61L19.1694 2L21.9994 4.83L11.3894 15.44Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
