import { clsx } from 'clsx';

import { AnchorLink, Image } from '@/components/ui';
import { OWNER } from '@/constants';
import { Container } from '@/layouts';
import BrandLogo from 'public/images/brand_logo.svg';

import { footerMenus, footerSubMenus, snsLinks } from './const';

export const GlobalFooter = () => {
  return (
    <footer
      className={clsx(
        'tw-w-full tw-pt-10 tw-pb-12 tw-px-spContentSide tw-bg-gray-100',
        'md:tw-pt-14 md:tw-pb-16'
      )}
    >
      <Container>
        <div className={clsx('md:tw-flex md:tw-gap-x-11 lg:tw-gap-x-24')}>
          <section
            className={clsx(
              'tw-flex tw-flex-row-reverse lg:tw-w-[10%] tw-justify-between tw-items-center',
              'md:tw-flex-col md:tw-justify-start'
            )}
          >
            <ul className="tw-flex tw-justify-center tw-gap-x-3">
              {snsLinks.map(({ href, title, icon: SnsIcon }, index) => (
                <li key={index}>
                  <AnchorLink href={href} aria-label={title} target="_blank">
                    <SnsIcon />
                  </AnchorLink>
                </li>
              ))}
            </ul>

            <BrandLogo
              className={clsx('md:tw-mt-6', 'tw-w-[100px] md:tw-w-[88px]')}
            />
          </section>

          <section className={clsx('tw-mt-14 md:tw-mt-0 lg:tw-w-[82%]')}>
            {/* リンクメニューエリア */}
            <ul
              className={clsx(
                'tw-w-max tw-grid tw-grid-cols-[auto_auto] tw-gap-x-10 tw-gap-y-4',
                'md:tw-grid-cols-[auto_auto_auto_auto] lg:tw-gap-x-12'
              )}
            >
              {footerMenus.map((menu, index) => (
                <li
                  key={index}
                  className="tw-w-max tw-text-ui13 tw-leading-4 tw-font-bold tw-truncate"
                >
                  <AnchorLink href={menu.path} target={menu.target}>
                    {menu.label}
                  </AnchorLink>
                </li>
              ))}
            </ul>

            <div
              className={clsx(
                'tw-mt-12 tw-flex tw-justify-between',
                'lg:tw-mt-8'
              )}
            >
              <div
                className={clsx(
                  'tw-flex tw-flex-col md:tw-justify-between',
                  'md:tw-w-1/2 lg:tw-w-full'
                )}
              >
                {/* サブメニュー（個人情報保護など）エリア  */}
                <nav>
                  <ul className="tw-flex tw-flex-wrap tw-text-ui12 tw-font-light tw-leading-4">
                    {footerSubMenus.map((menu, index) => (
                      <li
                        key={index}
                        className="tw-flex tw-mb-2 after:tw-w-[1px] after:tw-mx-2 after:tw-content-[''] after:last:tw-content-none after:tw-bg-black"
                      >
                        <div>
                          <AnchorLink href={menu.path}>{menu.label}</AnchorLink>
                        </div>
                      </li>
                    ))}
                  </ul>
                </nav>

                {/* コピーライト */}
                <small className="tw-mt-3 tw-text-ui10">©{OWNER.NAME}</small>
              </div>

              {/* Pマーク */}
              <div className="tw-block tw-flex-none tw-w-12 tw-h-12 tw-self-start">
                <AnchorLink href="https://privacymark.jp/" target="_blank">
                  <Image src="/images/img-pmark.png" alt="プライバシーマーク" />
                </AnchorLink>
              </div>
            </div>
          </section>
        </div>
      </Container>
    </footer>
  );
};
