import { zodResolver } from '@hookform/resolvers/zod';
import { clsx } from 'clsx';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import type {
  ContestDetailedSearchModalProps,
  ContestDetailedSearchFormVO,
} from './type';

import { IconLoader } from '@/components/icons';
import {
  HalfModal,
  Accordion,
  Button,
  FormFieldWrapper,
  FormFieldText,
  FormFieldSelectSingle,
  FormFieldDateRange,
  FormFieldCheckbox,
} from '@/components/ui';
import {
  ACCEPTANCE_TOTAL_OPTIONS,
  ENTRY_LAST_TOTAL_OPTIONS,
  PRIZE_TOP_AMOUNT_OPTIONS,
} from '@/constants/features/contest';
import { ContestDetailedSearchModalDocument } from '@/graphql/generated';
import { useQueryWrapper } from '@/libs/apollo';

import { FormFieldContestCategory } from './FormFieldContestCategory';
import { FormFieldContestJob } from './FormFieldContestJob';
import { FormFieldContestPrefecture } from './FormFieldContestPrefecture';
import {
  contestDetailedSearchFormSchema,
  formInitialValues,
  formLabel,
  placeholder,
} from './const';
import { generateContestWhere } from './util';

export const ContestDetailedSearchModal = ({
  isOpen,
  defaultValues,
  onClose,
  onSearch,
}: ContestDetailedSearchModalProps) => {
  const [queryWhere, setQueryWhere] = useState<
    ReturnType<typeof generateContestWhere>
  >(generateContestWhere(defaultValues ?? formInitialValues));

  const { control, handleSubmit, reset, getValues } =
    useForm<ContestDetailedSearchFormVO>({
      mode: 'onBlur',
      resolver: zodResolver(contestDetailedSearchFormSchema),
      defaultValues: defaultValues ?? formInitialValues,
    });

  const handleReset = () => {
    reset(defaultValues ?? formInitialValues);
    updateQueryWhere();
  };

  const handleClose = () => {
    reset(defaultValues ?? formInitialValues);
    onClose();
  };

  const { data: clientFetchedData, loading } = useQueryWrapper(
    ContestDetailedSearchModalDocument,
    {
      variables: { where: queryWhere },
      skip: !isOpen,
    }
  );

  const updateQueryWhere = () => {
    setQueryWhere(generateContestWhere(getValues()));
  };

  return (
    <HalfModal
      isOpen={isOpen}
      title="こだわり検索"
      ariaLabel="こだわり検索メニュー"
      maxWidthPx={552}
      onClose={handleClose}
      footer={
        <div
          className={clsx(
            'tw-flex tw-items-center tw-justify-between tw-bg-white tw-space-x-10 md:tw-flex-col md:tw-space-x-0 md:tw-space-y-4'
          )}
        >
          <p className={clsx('tw-flex tw-items-center tw-justify-center')}>
            <span className={clsx('tw-hidden md:tw-inline')}>
              絞り込み結果：
            </span>
            {loading ||
            clientFetchedData?.contestsAggregate.aggregate?.count ===
              undefined ? (
              <IconLoader />
            ) : (
              <span
                className={clsx('tw-max-w-[100px]')}
              >{`${clientFetchedData.contestsAggregate.aggregate.count}件`}</span>
            )}
          </p>
          <div
            className={clsx(
              'tw-flex-1 tw-flex tw-flex-row tw-space-x-2 md:tw-w-full'
            )}
          >
            <div
              className={clsx('tw-flex-1 md:tw-w-full tw-hidden md:tw-block')}
            >
              <Button theme="outline" onClick={handleReset} size="md">
                <span>条件をリセット</span>
              </Button>
            </div>
            <div className={clsx('tw-flex-1 md:tw-w-full md:tw-hidden')}>
              <Button theme="outline" onClick={handleReset} size="sm">
                <span>リセット</span>
              </Button>
            </div>
            <div
              className={clsx('tw-flex-1 md:tw-w-full tw-hidden md:tw-block')}
            >
              <Button theme="fill" onClick={handleSubmit(onSearch)} size="md">
                <span>この条件で検索</span>
              </Button>
            </div>
            <div className={clsx('tw-flex-1 md:tw-w-full md:tw-hidden')}>
              <Button theme="fill" onClick={handleSubmit(onSearch)} size="sm">
                <span>検索</span>
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <form className={clsx('tw-space-y-4')}>
        <FormFieldText
          name="keyword"
          label={formLabel.keyword}
          placeholder={placeholder.keyword}
          onAfterBlur={updateQueryWhere}
          control={control}
          showMandatoryMark={false}
        />
        <div>
          <Accordion
            label={formLabel.categoryIds}
            initialOpenState={
              defaultValues?.categoryIds !== undefined &&
              defaultValues.categoryIds.length > 0
            }
            stickToTop
          >
            <FormFieldContestCategory
              control={control}
              onAfterChange={updateQueryWhere}
            />
          </Accordion>
          <Accordion
            label={formLabel.jobIds}
            initialOpenState={
              defaultValues?.jobIds !== undefined &&
              defaultValues.jobIds.length > 0
            }
            stickToTop
          >
            <div className={clsx('tw-pb-4')}>
              <FormFieldContestJob
                control={control}
                onAfterChange={updateQueryWhere}
              />
            </div>
          </Accordion>
          <Accordion
            label={formLabel.prefectureIds}
            initialOpenState={
              defaultValues?.prefectureIds !== undefined &&
              defaultValues.prefectureIds.length > 0
            }
            stickToTop
          >
            <FormFieldContestPrefecture
              control={control}
              onAfterChange={updateQueryWhere}
            />
          </Accordion>
        </div>
        <FormFieldSelectSingle
          name="prizeTopAmountId"
          label={formLabel.prizeTopAmountId}
          placeholder={placeholder.prizeTopAmountId}
          options={PRIZE_TOP_AMOUNT_OPTIONS}
          control={control}
          showMandatoryMark={false}
          placeholderValue={null}
          onAfterChange={updateQueryWhere}
        />
        <FormFieldSelectSingle
          name="acceptanceTotalId"
          label={formLabel.acceptanceTotalId}
          placeholder={placeholder.acceptanceTotalId}
          options={ACCEPTANCE_TOTAL_OPTIONS}
          control={control}
          showMandatoryMark={false}
          placeholderValue={null}
          onAfterChange={updateQueryWhere}
        />
        <FormFieldSelectSingle
          name="entryLastTotalId"
          label={formLabel.entryLastTotalId}
          placeholder={placeholder.entryLastTotalId}
          options={ENTRY_LAST_TOTAL_OPTIONS}
          control={control}
          showMandatoryMark={false}
          placeholderValue={null}
          onAfterChange={updateQueryWhere}
        />
        <FormFieldDateRange
          name="endDate"
          label={formLabel.endDate}
          control={control}
          showMandatoryMark={false}
          onAfterChange={updateQueryWhere}
        />
        <FormFieldCheckbox
          name="includesClosed"
          checkboxLabel={formLabel.includesClosed}
          control={control}
          showMandatoryMark={false}
          onAfterChange={updateQueryWhere}
        />
        <FormFieldWrapper
          label="その他の条件"
          showLabel={true}
          showMandatoryMark={false}
        >
          <div
            className={clsx(
              'tw-pt-3 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-3'
            )}
          >
            <FormFieldCheckbox
              name="hasParticipationAward"
              checkboxLabel={formLabel.hasParticipationAward}
              showLabel={false}
              control={control}
              onAfterChange={updateQueryWhere}
            />
            <FormFieldCheckbox
              name="isRecommended"
              checkboxLabel={formLabel.isRecommended}
              showLabel={false}
              control={control}
              onAfterChange={updateQueryWhere}
            />
            <FormFieldCheckbox
              name="notRequiresFee"
              checkboxLabel={formLabel.notRequiresFee}
              showLabel={false}
              control={control}
              onAfterChange={updateQueryWhere}
            />
            <FormFieldCheckbox
              name="notRequiresSignup"
              checkboxLabel={formLabel.notRequiresSignup}
              showLabel={false}
              control={control}
              onAfterChange={updateQueryWhere}
            />
          </div>
        </FormFieldWrapper>
      </form>
    </HalfModal>
  );
};
