import { clsx } from 'clsx';
import { useCallback } from 'react';

import type { InputDateRangeProps } from './type';

import { InputDate } from '@/components/ui';

export const InputDateRange = ({
  inputRef,
  id,
  name,
  value,
  size,
  theme,
  dateFormat,
  disabled,
  minDate,
  maxDate,
  state,
  parseInput,
  onChange,
}: InputDateRangeProps) => {
  const handleChange = useCallback(
    (
      key: keyof typeof value,
      inputValue: InputDateRangeProps['value']['from']
    ) => {
      onChange({
        ...value,
        [key]: inputValue,
      });
    },
    [value, onChange]
  );
  return (
    <div className={clsx('tw-flex tw-items-center')}>
      <div className={clsx('tw-flex-1')}>
        <InputDate
          inputRef={inputRef?.from}
          id={`${id}-from`}
          name={`${name}-from`}
          value={value.from}
          size={size}
          theme={theme}
          dateFormat={dateFormat}
          disabled={disabled?.from}
          minDate={minDate?.from}
          maxDate={value?.to ?? maxDate?.from}
          state={state?.from}
          parseInput={parseInput}
          onChange={(inputValue) => handleChange('from', inputValue)}
        />
      </div>
      <span
        className={clsx(
          'tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-text-2xl'
        )}
      >
        ~
      </span>
      <div className={clsx('tw-flex-1')}>
        <InputDate
          inputRef={inputRef?.to}
          id={`${id}-to`}
          name={`${name}-to`}
          value={value.to}
          size={size}
          theme={theme}
          dateFormat={dateFormat}
          disabled={disabled?.to}
          minDate={value.from ?? minDate?.to}
          maxDate={maxDate?.to}
          state={state?.to}
          parseInput={parseInput}
          calendarPosition="right"
          onChange={(inputValue) => handleChange('to', inputValue)}
        />
      </div>
    </div>
  );
};
