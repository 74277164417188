import type {
  PageQuery,
  ParsedContestListUrlQuery,
} from '@/components/pages/ContestList/type';

export const generateContestListUrlQuery = (
  condition: Partial<PageQuery>
): ParsedContestListUrlQuery => {
  const base = {
    q: condition.keyword?.length ? condition.keyword : undefined,
    c: condition.categoryIds,
    sc: condition.subcategoryIds,
    j: condition.jobIds,
    pf: condition.prefectureIds,
    pta: condition.prizeTopAmountId ?? undefined,
    at: condition.acceptanceTotalId ?? undefined,
    elt: condition.entryLastTotalId ?? undefined,
    edf: condition.endDate?.from,
    edt: condition.endDate?.to,
    ic: condition.includesClosed,
    hpa: condition.hasParticipationAward,
    ir: condition.isRecommended,
    nrf: condition.notRequiresFee,
    nrs: condition.notRequiresSignup,
    s: condition.sortKey,
    pp: condition.perPage,
    p: condition.page,
  };

  return Object.fromEntries(
    Object.entries(base).filter(([_, v]) => {
      if (Array.isArray(v)) {
        return v.length > 0;
      }
      if (typeof v === 'boolean') {
        return v;
      }
      return v !== undefined;
    })
  );
};
