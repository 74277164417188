import { clsx } from 'clsx';
import { useMemo } from 'react';
import { useController, useWatch } from 'react-hook-form';

import type { FormFieldContestJobProps } from './type';

import {
  FormFieldWrapper,
  InputCheckbox,
  FormFieldCheckboxGroup,
} from '@/components/ui';
import { NON_JOB_OPTION } from '@/constants/features/contest';
import { masterData } from '@/data/master';
import { generateOptions } from '@/utils/ui';

import { FIELD_NAME } from './const';

export const FormFieldContestJob = ({
  control,
  onAfterChange,
}: FormFieldContestJobProps) => {
  const {
    field: { ref, onChange },
    fieldState: { error },
  } = useController({ control, name: FIELD_NAME });

  const watchedJobIds = useWatch({
    control,
    name: FIELD_NAME,
  });

  const studentJobs = useMemo(
    () => masterData.contestJobs.filter(({ id }) => id !== 1),
    []
  );

  const isAllStudentJobSelected = useMemo(
    () => studentJobs.every(({ id }) => watchedJobIds.includes(id)),
    [studentJobs, watchedJobIds]
  );

  const isSomeStudentJobSelected = useMemo(
    () =>
      !isAllStudentJobSelected &&
      studentJobs.some(({ id }) => watchedJobIds.includes(id)),
    [isAllStudentJobSelected, studentJobs, watchedJobIds]
  );

  const handleCheckAllStudent = () => {
    if (isAllStudentJobSelected) {
      const filteredJobIds = watchedJobIds.filter(
        (id) => !studentJobs.map((job) => job.id).includes(id)
      );
      onChange(filteredJobIds);
    } else {
      const concatedJobIds = Array.from(
        new Set([...watchedJobIds, ...studentJobs.map((job) => job.id)])
      );
      onChange(concatedJobIds);
    }
    if (onAfterChange !== undefined) {
      onAfterChange();
    }
  };

  const proJobs = useMemo(
    () => masterData.contestJobs.filter(({ id }) => id === 1),
    []
  );

  return (
    <FormFieldWrapper error={error} showLabel={false}>
      <div className={clsx('tw-space-y-4')}>
        <InputCheckbox
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          inputRef={ref}
          label="学生（すべて）"
          value={isAllStudentJobSelected}
          isIndeterminate={isSomeStudentJobSelected}
          onChange={handleCheckAllStudent}
        />
        <div className={clsx('tw-pl-6')}>
          <FormFieldCheckboxGroup
            name={FIELD_NAME}
            options={generateOptions(studentJobs)}
            control={control}
            showLabel={false}
            showError={false}
            onAfterChange={onAfterChange}
          />
        </div>
        <FormFieldCheckboxGroup
          name={FIELD_NAME}
          options={generateOptions(proJobs)}
          control={control}
          showLabel={false}
          showError={false}
          onAfterChange={onAfterChange}
        />
        <FormFieldCheckboxGroup
          name={FIELD_NAME}
          options={generateOptions([NON_JOB_OPTION])}
          control={control}
          showLabel={false}
          showError={false}
          onAfterChange={onAfterChange}
        />
      </div>
    </FormFieldWrapper>
  );
};
