import type { RefCallback, RefObject } from 'react';

export const combinedRefs = <T>(
  ...refs: Array<RefObject<T> | RefCallback<T>>
): RefCallback<T> => {
  const handleRefs = (el: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(el);
      } else if (ref) {
        (ref as React.MutableRefObject<T | null>).current = el;
      }
    });
  };
  return handleRefs;
};
