import { useMemo } from 'react';
import { useController } from 'react-hook-form';

import type { FormFieldDateRangeProps } from './type';
import type { InputDateRangeProps } from '@/components/ui/input/InputDateRange/type';
import type { FieldError } from 'react-hook-form';

import { FormFieldWrapper, InputDateRange } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

import { isDateRangeFieldError } from './util';

export const FormFieldDateRange = ({
  name,
  control,
  shouldUnregister,
  defaultValue,
  onAfterChange,
  ...props
}: FormFieldDateRangeProps) => {
  const {
    field: { ref, onChange, ...field },
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const errors = useMemo(() => {
    return isDateRangeFieldError(error)
      ? [error?.from, error?.to].filter(
          (item): item is FieldError => item !== undefined
        )
      : [];
  }, [error]);

  const { wrapProps, rest: inputProps } = extractWrapperProps({
    ...props,
    name,
  });

  const handleChange: InputDateRangeProps['onChange'] = (value) => {
    onChange(value);
    if (onAfterChange !== undefined) {
      onAfterChange();
    }
  };

  return (
    <FormFieldWrapper error={errors} {...wrapProps}>
      <InputDateRange
        data-testid={wrapProps.id}
        id={wrapProps.id}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputRef={ref}
        state={
          error !== undefined
            ? {
                from:
                  isDateRangeFieldError(error) && error.from != null
                    ? 'error'
                    : undefined,
                to:
                  isDateRangeFieldError(error) && error.to != null
                    ? 'error'
                    : undefined,
              }
            : undefined
        }
        onChange={handleChange}
        {...inputProps}
        {...field}
      />
    </FormFieldWrapper>
  );
};
