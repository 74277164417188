import ja from 'date-fns/locale/ja';
import React, { useState, useEffect } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import type { CalendarProps } from './type';

import { IconLoader } from '@/components/icons';
import { DATE_FORMAT } from '@/constants/date';
import { formatDate } from '@/utils/date';

export const Calendar = ({
  value,
  onChange,
  dateFormat = DATE_FORMAT.yyyyMMdd,
  minDate,
  maxDate,
  disabled,
}: CalendarProps) => {
  const [DatePicker, setDatePicker] = useState<
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    typeof import('react-datepicker').default | null
  >(null);

  useEffect(() => {
    const loadDatePicker = async () => {
      const { default: DatePicker, registerLocale } = await import(
        'react-datepicker'
      );
      registerLocale('ja', ja);
      setDatePicker(() => DatePicker);
    };

    loadDatePicker();
  }, []);

  if (!DatePicker) {
    return <IconLoader />;
  }

  return (
    <DatePicker
      selected={value ? new Date(value) : undefined}
      onChange={(date) => onChange(formatDate(date, dateFormat))}
      disabled={disabled}
      locale="ja"
      dateFormat={dateFormat}
      minDate={minDate ? new Date(minDate) : undefined}
      maxDate={maxDate ? new Date(maxDate) : undefined}
      inline={true}
    />
  );
};
